import { api } from './createApi'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiV1AccountsAuthenticate: build.mutation<
      PostApiV1AccountsAuthenticateApiResponse,
      PostApiV1AccountsAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/authenticate`,
        method: 'POST',
        body: queryArg.authRequest,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    putApiV1AccountsChangeName: build.mutation<
      PutApiV1AccountsChangeNameApiResponse,
      PutApiV1AccountsChangeNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/change/name`,
        method: 'PUT',
        params: {
          id: queryArg.id,
          name: queryArg.name,
          username: queryArg.username,
        },
      }),
    }),
    deleteApiV1AccountsDelete: build.mutation<
      DeleteApiV1AccountsDeleteApiResponse,
      DeleteApiV1AccountsDeleteApiArg
    >({
      query: () => ({ url: `/api/v1/accounts/delete`, method: 'DELETE' }),
    }),
    putApiV1AccountsEmailChangeByHash: build.mutation<
      PutApiV1AccountsEmailChangeByHashApiResponse,
      PutApiV1AccountsEmailChangeByHashApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/email/change/${queryArg.hash}`,
        method: 'PUT',
      }),
    }),
    putApiV1AccountsEmailConfirmChangeByHash: build.mutation<
      PutApiV1AccountsEmailConfirmChangeByHashApiResponse,
      PutApiV1AccountsEmailConfirmChangeByHashApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/email/confirm-change/${queryArg.hash}`,
        method: 'PUT',
        body: queryArg.emailChangeConfirm,
      }),
    }),
    putApiV1AccountsEmailRequestChange: build.mutation<
      PutApiV1AccountsEmailRequestChangeApiResponse,
      PutApiV1AccountsEmailRequestChangeApiArg
    >({
      query: () => ({
        url: `/api/v1/accounts/email/request-change`,
        method: 'PUT',
      }),
    }),
    putApiV1AccountsPasswordConfirmChangeByHash: build.mutation<
      PutApiV1AccountsPasswordConfirmChangeByHashApiResponse,
      PutApiV1AccountsPasswordConfirmChangeByHashApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/password/confirm-change/${queryArg.hash}`,
        method: 'PUT',
        body: queryArg.passwordUpdate,
      }),
    }),
    postApiV1AccountsPasswordConfirmByHash: build.mutation<
      PostApiV1AccountsPasswordConfirmByHashApiResponse,
      PostApiV1AccountsPasswordConfirmByHashApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/password/confirm/${queryArg.hash}`,
        method: 'POST',
        body: queryArg.pwdRecoveryUpdate,
      }),
    }),
    putApiV1AccountsPasswordRequestChange: build.mutation<
      PutApiV1AccountsPasswordRequestChangeApiResponse,
      PutApiV1AccountsPasswordRequestChangeApiArg
    >({
      query: () => ({
        url: `/api/v1/accounts/password/request-change`,
        method: 'PUT',
      }),
    }),
    postApiV1AccountsPasswordReset: build.mutation<
      PostApiV1AccountsPasswordResetApiResponse,
      PostApiV1AccountsPasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/password/reset`,
        method: 'POST',
        body: queryArg.pwdRecoveryRequest,
      }),
    }),
    getApiV1AccountsProfile: build.query<
      GetApiV1AccountsProfileApiResponse,
      GetApiV1AccountsProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/profile`,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    postApiV1AccountsRegister: build.mutation<
      PostApiV1AccountsRegisterApiResponse,
      PostApiV1AccountsRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/register`,
        method: 'POST',
        body: queryArg.accountCreate,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    postApiV1AccountsReselectUser: build.mutation<
      PostApiV1AccountsReselectUserApiResponse,
      PostApiV1AccountsReselectUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/reselect-user`,
        method: 'POST',
        params: {
          userId: queryArg.userId,
          userName: queryArg.userName,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    getApiV1AccountsById: build.query<
      GetApiV1AccountsByIdApiResponse,
      GetApiV1AccountsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/${queryArg.id}`,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    putApiV1AdminChangeRole: build.mutation<
      PutApiV1AdminChangeRoleApiResponse,
      PutApiV1AdminChangeRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/change/role`,
        method: 'PUT',
        params: {
          id: queryArg.id,
          role: queryArg.role,
          username: queryArg.username,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    deleteApiV1AdminDelete: build.mutation<
      DeleteApiV1AdminDeleteApiResponse,
      DeleteApiV1AdminDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/delete`,
        method: 'DELETE',
        params: {
          email: queryArg.email,
          id: queryArg.id,
          username: queryArg.username,
        },
      }),
    }),
    getApiV1AdminList: build.query<
      GetApiV1AdminListApiResponse,
      GetApiV1AdminListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/list`,
        params: {
          accId: queryArg.accId,
          email: queryArg.email,
          name: queryArg.name,
          role: queryArg.role,
          userId: queryArg.userId,
          username: queryArg.username,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    deleteApiV1AdminPurge: build.mutation<
      DeleteApiV1AdminPurgeApiResponse,
      DeleteApiV1AdminPurgeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/purge`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    getApiV1AdminShow: build.query<
      GetApiV1AdminShowApiResponse,
      GetApiV1AdminShowApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/show`,
        params: {
          email: queryArg.email,
          id: queryArg.id,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    getApiV1AdminShowUser: build.query<
      GetApiV1AdminShowUserApiResponse,
      GetApiV1AdminShowUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/show/user`,
        params: {
          id: queryArg.id,
          username: queryArg.username,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    postApiV1Apps: build.mutation<
      PostApiV1AppsApiResponse,
      PostApiV1AppsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps`,
        method: 'POST',
        body: queryArg.body,
        params: { enable: queryArg.enable },
      }),
    }),
    getApiV1AppsEnabled: build.query<
      GetApiV1AppsEnabledApiResponse,
      GetApiV1AppsEnabledApiArg
    >({
      query: () => ({ url: `/api/v1/apps/enabled` }),
    }),
    getApiV1AppsFeatured: build.query<
      GetApiV1AppsFeaturedApiResponse,
      GetApiV1AppsFeaturedApiArg
    >({
      query: () => ({ url: `/api/v1/apps/featured` }),
    }),
    getApiV1AppsInstalled: build.query<
      GetApiV1AppsInstalledApiResponse,
      GetApiV1AppsInstalledApiArg
    >({
      query: () => ({ url: `/api/v1/apps/installed` }),
    }),
    getApiV1AppsPinned: build.query<
      GetApiV1AppsPinnedApiResponse,
      GetApiV1AppsPinnedApiArg
    >({
      query: () => ({ url: `/api/v1/apps/pinned` }),
    }),
    getApiV1AppsUser: build.query<
      GetApiV1AppsUserApiResponse,
      GetApiV1AppsUserApiArg
    >({
      query: () => ({ url: `/api/v1/apps/user` }),
    }),
    putApiV1AppsUserByAppId: build.mutation<
      PutApiV1AppsUserByAppIdApiResponse,
      PutApiV1AppsUserByAppIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/user/${queryArg.appId}`,
        method: 'PUT',
        params: { pin: queryArg.pin },
      }),
    }),
    deleteApiV1AppsUserByAppId: build.mutation<
      DeleteApiV1AppsUserByAppIdApiResponse,
      DeleteApiV1AppsUserByAppIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/user/${queryArg.appId}`,
        method: 'DELETE',
      }),
    }),
    deleteApiV1AppsUserByAppIdPin: build.mutation<
      DeleteApiV1AppsUserByAppIdPinApiResponse,
      DeleteApiV1AppsUserByAppIdPinApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/user/${queryArg.appId}/pin`,
        method: 'DELETE',
      }),
    }),
    putApiV1AppsUserByAppIdReorder: build.mutation<
      PutApiV1AppsUserByAppIdReorderApiResponse,
      PutApiV1AppsUserByAppIdReorderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/user/${queryArg.appId}/reorder`,
        method: 'PUT',
        params: { before: queryArg.before, markerAppId: queryArg.markerAppId },
      }),
    }),
    getApiV1AppsByAppId: build.query<
      GetApiV1AppsByAppIdApiResponse,
      GetApiV1AppsByAppIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/apps/${queryArg.appId}` }),
    }),
    putApiV1AppsByAppId: build.mutation<
      PutApiV1AppsByAppIdApiResponse,
      PutApiV1AppsByAppIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}`,
        method: 'PUT',
        body: queryArg.body,
        params: { enable: queryArg.enable },
      }),
    }),
    deleteApiV1AppsByAppId: build.mutation<
      DeleteApiV1AppsByAppIdApiResponse,
      DeleteApiV1AppsByAppIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}`,
        method: 'DELETE',
      }),
    }),
    putApiV1AppsByAppIdDisable: build.mutation<
      PutApiV1AppsByAppIdDisableApiResponse,
      PutApiV1AppsByAppIdDisableApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}/disable`,
        method: 'PUT',
      }),
    }),
    putApiV1AppsByAppIdEnable: build.mutation<
      PutApiV1AppsByAppIdEnableApiResponse,
      PutApiV1AppsByAppIdEnableApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}/enable`,
        method: 'PUT',
      }),
    }),
    putApiV1AppsByAppIdFeatured: build.mutation<
      PutApiV1AppsByAppIdFeaturedApiResponse,
      PutApiV1AppsByAppIdFeaturedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}/featured`,
        method: 'PUT',
      }),
    }),
    deleteApiV1AppsByAppIdFeatured: build.mutation<
      DeleteApiV1AppsByAppIdFeaturedApiResponse,
      DeleteApiV1AppsByAppIdFeaturedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}/featured`,
        method: 'DELETE',
      }),
    }),
    putApiV1AppsByAppIdFeaturedReorder: build.mutation<
      PutApiV1AppsByAppIdFeaturedReorderApiResponse,
      PutApiV1AppsByAppIdFeaturedReorderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}/featured/reorder`,
        method: 'PUT',
        params: { before: queryArg.before, markerAppId: queryArg.markerAppId },
      }),
    }),
    getApiV1AppsByAppIdStats: build.query<
      GetApiV1AppsByAppIdStatsApiResponse,
      GetApiV1AppsByAppIdStatsApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/apps/${queryArg.appId}/stats` }),
    }),
    putApiV1AppsByAppIdAndTypeDefault: build.mutation<
      PutApiV1AppsByAppIdAndTypeDefaultApiResponse,
      PutApiV1AppsByAppIdAndTypeDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}/${queryArg['type']}/default`,
        method: 'PUT',
      }),
    }),
    putApiV1AppsByAppIdAndTypeUserDefault: build.mutation<
      PutApiV1AppsByAppIdAndTypeUserDefaultApiResponse,
      PutApiV1AppsByAppIdAndTypeUserDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}/${queryArg['type']}/user-default`,
        method: 'PUT',
      }),
    }),
    deleteApiV1AppsByAppIdAndTypeUserDefault: build.mutation<
      DeleteApiV1AppsByAppIdAndTypeUserDefaultApiResponse,
      DeleteApiV1AppsByAppIdAndTypeUserDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/apps/${queryArg.appId}/${queryArg['type']}/user-default`,
        method: 'DELETE',
      }),
    }),
    getApiV1Block: build.query<GetApiV1BlockApiResponse, GetApiV1BlockApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/block`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    postApiV1BlockByUserId: build.mutation<
      PostApiV1BlockByUserIdApiResponse,
      PostApiV1BlockByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/block/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    deleteApiV1BlockByUserId: build.mutation<
      DeleteApiV1BlockByUserIdApiResponse,
      DeleteApiV1BlockByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/block/${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    getApiV1CustomEmojis: build.query<
      GetApiV1CustomEmojisApiResponse,
      GetApiV1CustomEmojisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/custom_emojis`,
        params: { shortcode: queryArg.shortcode },
      }),
    }),
    postApiV1CustomEmojis: build.mutation<
      PostApiV1CustomEmojisApiResponse,
      PostApiV1CustomEmojisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/custom_emojis`,
        method: 'POST',
        body: queryArg.emoji,
        params: { overwrite: queryArg.overwrite },
      }),
    }),
    deleteApiV1CustomEmojis: build.mutation<
      DeleteApiV1CustomEmojisApiResponse,
      DeleteApiV1CustomEmojisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/custom_emojis`,
        method: 'DELETE',
        params: { shortcode: queryArg.shortcode, category: queryArg.category },
      }),
    }),
    getApiV1CustomEmojisExport: build.query<
      GetApiV1CustomEmojisExportApiResponse,
      GetApiV1CustomEmojisExportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/custom_emojis/export`,
        params: { category: queryArg.category },
      }),
    }),
    postApiV1CustomEmojisImport: build.mutation<
      PostApiV1CustomEmojisImportApiResponse,
      PostApiV1CustomEmojisImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/custom_emojis/import`,
        method: 'POST',
        body: queryArg.body,
        params: {
          visible: queryArg.visible,
          category: queryArg.category,
          overwrite: queryArg.overwrite,
        },
      }),
    }),
    getApiV1CustomEmojisSearch: build.query<
      GetApiV1CustomEmojisSearchApiResponse,
      GetApiV1CustomEmojisSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/custom_emojis/search`,
        params: { shortcode: queryArg.shortcode },
      }),
    }),
    postApiV1CustomEmojisUpload: build.mutation<
      PostApiV1CustomEmojisUploadApiResponse,
      PostApiV1CustomEmojisUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/custom_emojis/upload`,
        method: 'POST',
        body: queryArg.body,
        params: {
          shortcode: queryArg.shortcode,
          visible: queryArg.visible,
          category: queryArg.category,
          overwrite: queryArg.overwrite,
        },
      }),
    }),
    getApiV1EmployerProfiles: build.query<
      GetApiV1EmployerProfilesApiResponse,
      GetApiV1EmployerProfilesApiArg
    >({
      query: () => ({ url: `/api/v1/employer-profiles` }),
    }),
    putApiV1EmployerProfiles: build.mutation<
      PutApiV1EmployerProfilesApiResponse,
      PutApiV1EmployerProfilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/employer-profiles`,
        method: 'PUT',
        body: queryArg.employerProfile,
      }),
    }),
    postApiV1EmployerProfiles: build.mutation<
      PostApiV1EmployerProfilesApiResponse,
      PostApiV1EmployerProfilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/employer-profiles`,
        method: 'POST',
        body: queryArg.employerProfile,
      }),
    }),
    getApiV1EmployerProfilesUserByUserId: build.query<
      GetApiV1EmployerProfilesUserByUserIdApiResponse,
      GetApiV1EmployerProfilesUserByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/employer-profiles/user/${queryArg.userId}`,
      }),
    }),
    getApiV1EmployerProfilesByProfileId: build.query<
      GetApiV1EmployerProfilesByProfileIdApiResponse,
      GetApiV1EmployerProfilesByProfileIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/employer-profiles/${queryArg.profileId}`,
      }),
    }),
    deleteApiV1EmployerProfilesByProfileId: build.mutation<
      DeleteApiV1EmployerProfilesByProfileIdApiResponse,
      DeleteApiV1EmployerProfilesByProfileIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/employer-profiles/${queryArg.profileId}`,
        method: 'DELETE',
      }),
    }),
    putApiV1EmployerProfilesByProfileIdCurrent: build.mutation<
      PutApiV1EmployerProfilesByProfileIdCurrentApiResponse,
      PutApiV1EmployerProfilesByProfileIdCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/employer-profiles/${queryArg.profileId}/current`,
        method: 'PUT',
      }),
    }),
    getApiV1Instance: build.query<
      GetApiV1InstanceApiResponse,
      GetApiV1InstanceApiArg
    >({
      query: () => ({ url: `/api/v1/instance` }),
    }),
    getApiV1InstanceDomains: build.query<
      GetApiV1InstanceDomainsApiResponse,
      GetApiV1InstanceDomainsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/instance/domains`,
        params: {
          domain: queryArg.domain,
          limit: queryArg.limit,
          protocol: queryArg.protocol,
        },
      }),
    }),
    getApiV1InstanceExtendedDescription: build.query<
      GetApiV1InstanceExtendedDescriptionApiResponse,
      GetApiV1InstanceExtendedDescriptionApiArg
    >({
      query: () => ({ url: `/api/v1/instance/extended_description` }),
    }),
    getApiV1JobApplications: build.query<
      GetApiV1JobApplicationsApiResponse,
      GetApiV1JobApplicationsApiArg
    >({
      query: () => ({ url: `/api/v1/job-applications` }),
    }),
    putApiV1JobApplications: build.mutation<
      PutApiV1JobApplicationsApiResponse,
      PutApiV1JobApplicationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-applications`,
        method: 'PUT',
        body: queryArg.jobApplication,
      }),
    }),
    postApiV1JobApplications: build.mutation<
      PostApiV1JobApplicationsApiResponse,
      PostApiV1JobApplicationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-applications`,
        method: 'POST',
        body: queryArg.jobApplication,
      }),
    }),
    getApiV1JobApplicationsByApplicationId: build.query<
      GetApiV1JobApplicationsByApplicationIdApiResponse,
      GetApiV1JobApplicationsByApplicationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-applications/${queryArg.applicationId}`,
      }),
    }),
    deleteApiV1JobApplicationsByApplicationId: build.mutation<
      DeleteApiV1JobApplicationsByApplicationIdApiResponse,
      DeleteApiV1JobApplicationsByApplicationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-applications/${queryArg.applicationId}`,
        method: 'DELETE',
      }),
    }),
    getApiV1JobPostings: build.query<
      GetApiV1JobPostingsApiResponse,
      GetApiV1JobPostingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-postings`,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    putApiV1JobPostings: build.mutation<
      PutApiV1JobPostingsApiResponse,
      PutApiV1JobPostingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-postings`,
        method: 'PUT',
        body: queryArg.jobPosting,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    postApiV1JobPostings: build.mutation<
      PostApiV1JobPostingsApiResponse,
      PostApiV1JobPostingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-postings`,
        method: 'POST',
        body: queryArg.jobPosting,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    getApiV1JobPostingsByPostingId: build.query<
      GetApiV1JobPostingsByPostingIdApiResponse,
      GetApiV1JobPostingsByPostingIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-postings/${queryArg.postingId}`,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    deleteApiV1JobPostingsByPostingId: build.mutation<
      DeleteApiV1JobPostingsByPostingIdApiResponse,
      DeleteApiV1JobPostingsByPostingIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-postings/${queryArg.postingId}`,
        method: 'DELETE',
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    getApiV1JobProfiles: build.query<
      GetApiV1JobProfilesApiResponse,
      GetApiV1JobProfilesApiArg
    >({
      query: () => ({ url: `/api/v1/job-profiles` }),
    }),
    putApiV1JobProfiles: build.mutation<
      PutApiV1JobProfilesApiResponse,
      PutApiV1JobProfilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-profiles`,
        method: 'PUT',
        body: queryArg.employeeProfile,
      }),
    }),
    postApiV1JobProfiles: build.mutation<
      PostApiV1JobProfilesApiResponse,
      PostApiV1JobProfilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-profiles`,
        method: 'POST',
        body: queryArg.employeeProfile,
      }),
    }),
    getApiV1JobProfilesUserByUserId: build.query<
      GetApiV1JobProfilesUserByUserIdApiResponse,
      GetApiV1JobProfilesUserByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-profiles/user/${queryArg.userId}`,
      }),
    }),
    getApiV1JobProfilesByProfileId: build.query<
      GetApiV1JobProfilesByProfileIdApiResponse,
      GetApiV1JobProfilesByProfileIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-profiles/${queryArg.profileId}`,
      }),
    }),
    deleteApiV1JobProfilesByProfileId: build.mutation<
      DeleteApiV1JobProfilesByProfileIdApiResponse,
      DeleteApiV1JobProfilesByProfileIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-profiles/${queryArg.profileId}`,
        method: 'DELETE',
      }),
    }),
    putApiV1JobProfilesByProfileIdCurrent: build.mutation<
      PutApiV1JobProfilesByProfileIdCurrentApiResponse,
      PutApiV1JobProfilesByProfileIdCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/job-profiles/${queryArg.profileId}/current`,
        method: 'PUT',
      }),
    }),
    getApiV1JobsListStats: build.query<
      GetApiV1JobsListStatsApiResponse,
      GetApiV1JobsListStatsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/jobs/list-stats`,
        params: { queue: queryArg.queue },
      }),
    }),
    getApiV1JobsListStatsAll: build.query<
      GetApiV1JobsListStatsAllApiResponse,
      GetApiV1JobsListStatsAllApiArg
    >({
      query: () => ({ url: `/api/v1/jobs/list-stats-all` }),
    }),
    getApiV1JobsLists: build.query<
      GetApiV1JobsListsApiResponse,
      GetApiV1JobsListsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/jobs/lists`,
        params: { listKind: queryArg.listKind, queue: queryArg.queue },
      }),
    }),
    getApiV1JobsStats: build.query<
      GetApiV1JobsStatsApiResponse,
      GetApiV1JobsStatsApiArg
    >({
      query: () => ({ url: `/api/v1/jobs/stats` }),
    }),
    getApiV1JobsStatsPerDay: build.query<
      GetApiV1JobsStatsPerDayApiResponse,
      GetApiV1JobsStatsPerDayApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/jobs/stats-per-day`,
        params: { lastDays: queryArg.lastDays },
      }),
    }),
    getApiV1Lists: build.query<GetApiV1ListsApiResponse, GetApiV1ListsApiArg>({
      query: () => ({ url: `/api/v1/lists` }),
    }),
    postApiV1Lists: build.mutation<
      PostApiV1ListsApiResponse,
      PostApiV1ListsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/lists`,
        method: 'POST',
        body: queryArg.userListCreate,
      }),
    }),
    getApiV1ListsByListId: build.query<
      GetApiV1ListsByListIdApiResponse,
      GetApiV1ListsByListIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/lists/${queryArg.listId}` }),
    }),
    putApiV1ListsByListId: build.mutation<
      PutApiV1ListsByListIdApiResponse,
      PutApiV1ListsByListIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/lists/${queryArg.listId}`,
        method: 'PUT',
        body: queryArg.userListCreate,
      }),
    }),
    deleteApiV1ListsByListId: build.mutation<
      DeleteApiV1ListsByListIdApiResponse,
      DeleteApiV1ListsByListIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/lists/${queryArg.listId}`,
        method: 'DELETE',
      }),
    }),
    getApiV1ListsByListIdUsers: build.query<
      GetApiV1ListsByListIdUsersApiResponse,
      GetApiV1ListsByListIdUsersApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/lists/${queryArg.listId}/users` }),
    }),
    postApiV1ListsByListIdUsersAndUserId: build.mutation<
      PostApiV1ListsByListIdUsersAndUserIdApiResponse,
      PostApiV1ListsByListIdUsersAndUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/lists/${queryArg.listId}/users/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    deleteApiV1ListsByListIdUsersAndUserId: build.mutation<
      DeleteApiV1ListsByListIdUsersAndUserIdApiResponse,
      DeleteApiV1ListsByListIdUsersAndUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/lists/${queryArg.listId}/users/${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    getApiV1Mute: build.query<GetApiV1MuteApiResponse, GetApiV1MuteApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/mute`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    postApiV1MuteByUserId: build.mutation<
      PostApiV1MuteByUserIdApiResponse,
      PostApiV1MuteByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mute/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    deleteApiV1MuteByUserId: build.mutation<
      DeleteApiV1MuteByUserIdApiResponse,
      DeleteApiV1MuteByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mute/${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    getApiV1Notes: build.query<GetApiV1NotesApiResponse, GetApiV1NotesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/notes`,
        params: { userIds: queryArg.userIds },
      }),
    }),
    putApiV1Notes: build.mutation<
      PutApiV1NotesApiResponse,
      PutApiV1NotesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notes`,
        method: 'PUT',
        body: queryArg.noteUpdate,
      }),
    }),
    deleteApiV1NotesByUserId: build.mutation<
      DeleteApiV1NotesByUserIdApiResponse,
      DeleteApiV1NotesByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notes/${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    getApiV1Notifications: build.query<
      GetApiV1NotificationsApiResponse,
      GetApiV1NotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications`,
        params: {
          excludeTypes: queryArg.excludeTypes,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          status: queryArg.status,
          types: queryArg.types,
        },
      }),
    }),
    getApiV1NotificationsCount: build.query<
      GetApiV1NotificationsCountApiResponse,
      GetApiV1NotificationsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/count`,
        params: {
          excludeTypes: queryArg.excludeTypes,
          status: queryArg.status,
          types: queryArg.types,
        },
      }),
    }),
    postApiV1NotificationsMarkAll: build.mutation<
      PostApiV1NotificationsMarkAllApiResponse,
      PostApiV1NotificationsMarkAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/mark-all`,
        method: 'POST',
        params: { status: queryArg.status },
      }),
    }),
    getApiV1NotificationsById: build.query<
      GetApiV1NotificationsByIdApiResponse,
      GetApiV1NotificationsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/notifications/${queryArg.id}` }),
    }),
    postApiV1NotificationsByIdMark: build.mutation<
      PostApiV1NotificationsByIdMarkApiResponse,
      PostApiV1NotificationsByIdMarkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notifications/${queryArg.id}/mark`,
        method: 'POST',
        params: { status: queryArg.status },
      }),
    }),
    getApiV1Posts: build.query<GetApiV1PostsApiResponse, GetApiV1PostsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/posts`,
        params: {
          includeFollowStatus: queryArg.includeFollowStatus,
          limit: queryArg.limit,
          limitFirstLevelComments: queryArg.limitFirstLevelComments,
          limitSecondLevelComments: queryArg.limitSecondLevelComments,
          listId: queryArg.listId,
          maxLevel: queryArg.maxLevel,
          minLevel: queryArg.minLevel,
          offset: queryArg.offset,
          protocol: queryArg.protocol,
          repostsOnly: queryArg.repostsOnly,
          search: queryArg.search,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          tag: queryArg.tag,
          userId: queryArg.userId,
          username: queryArg.username,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    postApiV1Posts: build.mutation<
      PostApiV1PostsApiResponse,
      PostApiV1PostsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts`,
        method: 'POST',
        body: queryArg.createPost,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    getApiV1PostsFollowing: build.query<
      GetApiV1PostsFollowingApiResponse,
      GetApiV1PostsFollowingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/following`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          protocol: queryArg.protocol,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    getApiV1PostsLiked: build.query<
      GetApiV1PostsLikedApiResponse,
      GetApiV1PostsLikedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/liked`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          protocol: queryArg.protocol,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    getApiV1PostsMentioned: build.query<
      GetApiV1PostsMentionedApiResponse,
      GetApiV1PostsMentionedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/mentioned`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          private: queryArg['private'],
          protocol: queryArg.protocol,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    getApiV1PostsMy: build.query<
      GetApiV1PostsMyApiResponse,
      GetApiV1PostsMyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/my`,
        params: {
          bookmarkedOnly: queryArg.bookmarkedOnly,
          includeComments: queryArg.includeComments,
          limit: queryArg.limit,
          offset: queryArg.offset,
          repostsOnly: queryArg.repostsOnly,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    getApiV1PostsReplies: build.query<
      GetApiV1PostsRepliesApiResponse,
      GetApiV1PostsRepliesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/replies`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          userId: queryArg.userId,
          username: queryArg.username,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    getApiV1PostsByPostId: build.query<
      GetApiV1PostsByPostIdApiResponse,
      GetApiV1PostsByPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}`,
        params: {
          limitFirstLevelComments: queryArg.limitFirstLevelComments,
          limitSecondLevelComments: queryArg.limitSecondLevelComments,
          maxLevel: queryArg.maxLevel,
          minLevel: queryArg.minLevel,
          withComments: queryArg.withComments,
          withParents: queryArg.withParents,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    putApiV1PostsByPostId: build.mutation<
      PutApiV1PostsByPostIdApiResponse,
      PutApiV1PostsByPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}`,
        method: 'PUT',
        body: queryArg.createPost,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    deleteApiV1PostsByPostId: build.mutation<
      DeleteApiV1PostsByPostIdApiResponse,
      DeleteApiV1PostsByPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}`,
        method: 'DELETE',
      }),
    }),
    postApiV1PostsByPostIdBookmark: build.mutation<
      PostApiV1PostsByPostIdBookmarkApiResponse,
      PostApiV1PostsByPostIdBookmarkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/bookmark`,
        method: 'POST',
      }),
    }),
    deleteApiV1PostsByPostIdBookmark: build.mutation<
      DeleteApiV1PostsByPostIdBookmarkApiResponse,
      DeleteApiV1PostsByPostIdBookmarkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/bookmark`,
        method: 'DELETE',
      }),
    }),
    getApiV1PostsByPostIdComments: build.query<
      GetApiV1PostsByPostIdCommentsApiResponse,
      GetApiV1PostsByPostIdCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/comments`,
        params: {
          limit: queryArg.limit,
          limitFirstLevelComments: queryArg.limitFirstLevelComments,
          limitSecondLevelComments: queryArg.limitSecondLevelComments,
          maxLevel: queryArg.maxLevel,
          minLevel: queryArg.minLevel,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    postApiV1PostsByPostIdComments: build.mutation<
      PostApiV1PostsByPostIdCommentsApiResponse,
      PostApiV1PostsByPostIdCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/comments`,
        method: 'POST',
        body: queryArg.createPost,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    putApiV1PostsByPostIdCommentsAndCommentId: build.mutation<
      PutApiV1PostsByPostIdCommentsAndCommentIdApiResponse,
      PutApiV1PostsByPostIdCommentsAndCommentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/comments/${queryArg.commentId}`,
        method: 'PUT',
        body: queryArg.createPost,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    deleteApiV1PostsByPostIdCommentsAndCommentId: build.mutation<
      DeleteApiV1PostsByPostIdCommentsAndCommentIdApiResponse,
      DeleteApiV1PostsByPostIdCommentsAndCommentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/comments/${queryArg.commentId}`,
        method: 'DELETE',
      }),
    }),
    getApiV1PostsByPostIdEdits: build.query<
      GetApiV1PostsByPostIdEditsApiResponse,
      GetApiV1PostsByPostIdEditsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/edits`,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    getApiV1PostsByPostIdLikes: build.query<
      GetApiV1PostsByPostIdLikesApiResponse,
      GetApiV1PostsByPostIdLikesApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/posts/${queryArg.postId}/likes` }),
    }),
    postApiV1PostsByPostIdLikes: build.mutation<
      PostApiV1PostsByPostIdLikesApiResponse,
      PostApiV1PostsByPostIdLikesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/likes`,
        method: 'POST',
      }),
    }),
    deleteApiV1PostsByPostIdLikes: build.mutation<
      DeleteApiV1PostsByPostIdLikesApiResponse,
      DeleteApiV1PostsByPostIdLikesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/likes`,
        method: 'DELETE',
      }),
    }),
    postApiV1PostsByPostIdRepost: build.mutation<
      PostApiV1PostsByPostIdRepostApiResponse,
      PostApiV1PostsByPostIdRepostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/posts/${queryArg.postId}/repost`,
        method: 'POST',
        body: queryArg.createPost,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    getApiV1Reports: build.query<
      GetApiV1ReportsApiResponse,
      GetApiV1ReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reports`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    postApiV1Reports: build.mutation<
      PostApiV1ReportsApiResponse,
      PostApiV1ReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reports`,
        method: 'POST',
        body: queryArg.createReport,
      }),
    }),
    getApiV1ReportsByReportId: build.query<
      GetApiV1ReportsByReportIdApiResponse,
      GetApiV1ReportsByReportIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reports/${queryArg.reportId}` }),
    }),
    postApiV1ReportsByReportIdAddNote: build.mutation<
      PostApiV1ReportsByReportIdAddNoteApiResponse,
      PostApiV1ReportsByReportIdAddNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reports/${queryArg.reportId}/add-note`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiV1ReportsByReportIdAssignToSelf: build.mutation<
      PostApiV1ReportsByReportIdAssignToSelfApiResponse,
      PostApiV1ReportsByReportIdAssignToSelfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reports/${queryArg.reportId}/assign-to-self`,
        method: 'POST',
      }),
    }),
    postApiV1ReportsByReportIdRemoveNote: build.mutation<
      PostApiV1ReportsByReportIdRemoveNoteApiResponse,
      PostApiV1ReportsByReportIdRemoveNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reports/${queryArg.reportId}/remove-note`,
        method: 'POST',
        params: { index: queryArg.index },
      }),
    }),
    postApiV1ReportsByReportIdReopen: build.mutation<
      PostApiV1ReportsByReportIdReopenApiResponse,
      PostApiV1ReportsByReportIdReopenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reports/${queryArg.reportId}/reopen`,
        method: 'POST',
      }),
    }),
    postApiV1ReportsByReportIdResolve: build.mutation<
      PostApiV1ReportsByReportIdResolveApiResponse,
      PostApiV1ReportsByReportIdResolveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reports/${queryArg.reportId}/resolve`,
        method: 'POST',
      }),
    }),
    postApiV1ReportsByReportIdUnassign: build.mutation<
      PostApiV1ReportsByReportIdUnassignApiResponse,
      PostApiV1ReportsByReportIdUnassignApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reports/${queryArg.reportId}/unassign`,
        method: 'POST',
      }),
    }),
    getApiV1Rules: build.query<GetApiV1RulesApiResponse, GetApiV1RulesApiArg>({
      query: () => ({ url: `/api/v1/rules` }),
    }),
    putApiV1Rules: build.mutation<
      PutApiV1RulesApiResponse,
      PutApiV1RulesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/rules`,
        method: 'PUT',
        body: queryArg.rule,
      }),
    }),
    postApiV1Rules: build.mutation<
      PostApiV1RulesApiResponse,
      PostApiV1RulesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/rules`,
        method: 'POST',
        params: {
          hint: queryArg.hint,
          ordering: queryArg.ordering,
          text: queryArg.text,
        },
      }),
    }),
    deleteApiV1RulesById: build.mutation<
      DeleteApiV1RulesByIdApiResponse,
      DeleteApiV1RulesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/rules/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiV1Search: build.query<
      GetApiV1SearchApiResponse,
      GetApiV1SearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/search`,
        params: {
          filter: queryArg.filter,
          followers: queryArg.followers,
          following: queryArg.following,
          limit: queryArg.limit,
          offset: queryArg.offset,
          protocol: queryArg.protocol,
          q: queryArg.q,
          resolve: queryArg.resolve,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    getApiV1SettingsByNamespace: build.query<
      GetApiV1SettingsByNamespaceApiResponse,
      GetApiV1SettingsByNamespaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/settings/${queryArg['namespace']}`,
        params: { keys: queryArg.keys },
      }),
    }),
    putApiV1SettingsByNamespace: build.mutation<
      PutApiV1SettingsByNamespaceApiResponse,
      PutApiV1SettingsByNamespaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/settings/${queryArg['namespace']}`,
        method: 'PUT',
        body: queryArg.jsonNode,
      }),
    }),
    deleteApiV1SettingsByNamespace: build.mutation<
      DeleteApiV1SettingsByNamespaceApiResponse,
      DeleteApiV1SettingsByNamespaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/settings/${queryArg['namespace']}`,
        method: 'DELETE',
        params: { keys: queryArg.keys },
      }),
    }),
    getApiV1SettingsByNamespaceJson: build.query<
      GetApiV1SettingsByNamespaceJsonApiResponse,
      GetApiV1SettingsByNamespaceJsonApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/settings/${queryArg['namespace']}/json`,
        params: { keys: queryArg.keys },
      }),
    }),
    getApiV1TagsFeatured: build.query<
      GetApiV1TagsFeaturedApiResponse,
      GetApiV1TagsFeaturedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/featured`,
        params: { userId: queryArg.userId },
      }),
    }),
    postApiV1TagsFeaturedByName: build.mutation<
      PostApiV1TagsFeaturedByNameApiResponse,
      PostApiV1TagsFeaturedByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/featured/${queryArg.name}`,
        method: 'POST',
      }),
    }),
    deleteApiV1TagsFeaturedByName: build.mutation<
      DeleteApiV1TagsFeaturedByNameApiResponse,
      DeleteApiV1TagsFeaturedByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/featured/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    postApiV1TagsFollowByName: build.mutation<
      PostApiV1TagsFollowByNameApiResponse,
      PostApiV1TagsFollowByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/follow/${queryArg.name}`,
        method: 'POST',
        params: { protocol: queryArg.protocol },
      }),
    }),
    getApiV1TagsFollowed: build.query<
      GetApiV1TagsFollowedApiResponse,
      GetApiV1TagsFollowedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/followed`,
        params: { protocol: queryArg.protocol },
      }),
    }),
    postApiV1TagsUnfollowByName: build.mutation<
      PostApiV1TagsUnfollowByNameApiResponse,
      PostApiV1TagsUnfollowByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/unfollow/${queryArg.name}`,
        method: 'POST',
        params: { protocol: queryArg.protocol },
      }),
    }),
    getApiV1TagsByName: build.query<
      GetApiV1TagsByNameApiResponse,
      GetApiV1TagsByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/${queryArg.name}`,
        params: {
          historySince: queryArg.historySince,
          protocol: queryArg.protocol,
        },
      }),
    }),
    postApiV1Uploads: build.mutation<
      PostApiV1UploadsApiResponse,
      PostApiV1UploadsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads`,
        method: 'POST',
        body: queryArg.request1,
      }),
    }),
    deleteApiV1UploadsBatch: build.mutation<
      DeleteApiV1UploadsBatchApiResponse,
      DeleteApiV1UploadsBatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/batch`,
        method: 'DELETE',
        body: queryArg.body,
      }),
    }),
    postApiV1UploadsList: build.mutation<
      PostApiV1UploadsListApiResponse,
      PostApiV1UploadsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/list`,
        method: 'POST',
        body: queryArg.request,
      }),
    }),
    getApiV1UploadsUsages: build.query<
      GetApiV1UploadsUsagesApiResponse,
      GetApiV1UploadsUsagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/usages`,
        params: { ids: queryArg.ids },
      }),
    }),
    getApiV1UploadsById: build.query<
      GetApiV1UploadsByIdApiResponse,
      GetApiV1UploadsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/uploads/${queryArg.id}` }),
    }),
    deleteApiV1UploadsById: build.mutation<
      DeleteApiV1UploadsByIdApiResponse,
      DeleteApiV1UploadsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    putApiV1UploadsByIdMeta: build.mutation<
      PutApiV1UploadsByIdMetaApiResponse,
      PutApiV1UploadsByIdMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/${queryArg.id}/meta`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    postApiV1UploadsByIdMeta: build.mutation<
      PostApiV1UploadsByIdMetaApiResponse,
      PostApiV1UploadsByIdMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/${queryArg.id}/meta`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiV1UploadsByIdTags: build.mutation<
      PutApiV1UploadsByIdTagsApiResponse,
      PutApiV1UploadsByIdTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/${queryArg.id}/tags`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    postApiV1UploadsByIdTags: build.mutation<
      PostApiV1UploadsByIdTagsApiResponse,
      PostApiV1UploadsByIdTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/${queryArg.id}/tags`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiV1UploadsByIdThumbnail: build.mutation<
      PostApiV1UploadsByIdThumbnailApiResponse,
      PostApiV1UploadsByIdThumbnailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/uploads/${queryArg.id}/thumbnail`,
        method: 'POST',
        body: queryArg.request2,
      }),
    }),
    getApiV1UserDomainBlock: build.query<
      GetApiV1UserDomainBlockApiResponse,
      GetApiV1UserDomainBlockApiArg
    >({
      query: () => ({ url: `/api/v1/user-domain-block` }),
    }),
    postApiV1UserDomainBlockByDomain: build.mutation<
      PostApiV1UserDomainBlockByDomainApiResponse,
      PostApiV1UserDomainBlockByDomainApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user-domain-block/${queryArg.domain}`,
        method: 'POST',
      }),
    }),
    deleteApiV1UserDomainBlockByDomain: build.mutation<
      DeleteApiV1UserDomainBlockByDomainApiResponse,
      DeleteApiV1UserDomainBlockByDomainApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user-domain-block/${queryArg.domain}`,
        method: 'DELETE',
      }),
    }),
    getApiV1Users: build.query<GetApiV1UsersApiResponse, GetApiV1UsersApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        params: {
          full: queryArg.full,
          username: queryArg.username,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    postApiV1Users: build.mutation<
      PostApiV1UsersApiResponse,
      PostApiV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'POST',
        body: queryArg.userCreate,
        params: {
          email: queryArg.email,
          account_id: queryArg.accountId,
          htmlContent: queryArg.htmlContent,
        },
      }),
    }),
    deleteApiV1Users: build.mutation<
      DeleteApiV1UsersApiResponse,
      DeleteApiV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'DELETE',
        params: { id: queryArg.id, name: queryArg.name },
      }),
    }),
    getApiV1UsersConnections: build.query<
      GetApiV1UsersConnectionsApiResponse,
      GetApiV1UsersConnectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/connections`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          status: queryArg.status,
        },
      }),
    }),
    postApiV1UsersFollowByUsername: build.mutation<
      PostApiV1UsersFollowByUsernameApiResponse,
      PostApiV1UsersFollowByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/follow/${queryArg.username}`,
        method: 'POST',
        params: {
          domain: queryArg.domain,
          mutual: queryArg.mutual,
          scheme: queryArg.scheme,
        },
      }),
    }),
    postApiV1UsersFollowByUsernameAccept: build.mutation<
      PostApiV1UsersFollowByUsernameAcceptApiResponse,
      PostApiV1UsersFollowByUsernameAcceptApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/follow/${queryArg.username}/accept`,
        method: 'POST',
      }),
    }),
    postApiV1UsersFollowByUsernameReject: build.mutation<
      PostApiV1UsersFollowByUsernameRejectApiResponse,
      PostApiV1UsersFollowByUsernameRejectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/follow/${queryArg.username}/reject`,
        method: 'POST',
      }),
    }),
    postApiV1UsersFollowByUsernameUndo: build.mutation<
      PostApiV1UsersFollowByUsernameUndoApiResponse,
      PostApiV1UsersFollowByUsernameUndoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/follow/${queryArg.username}/undo`,
        method: 'POST',
        params: {
          domain: queryArg.domain,
          mutual: queryArg.mutual,
          scheme: queryArg.scheme,
        },
      }),
    }),
    getApiV1UsersFollowees: build.query<
      GetApiV1UsersFolloweesApiResponse,
      GetApiV1UsersFolloweesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/followees`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          status: queryArg.status,
        },
      }),
    }),
    getApiV1UsersFollowers: build.query<
      GetApiV1UsersFollowersApiResponse,
      GetApiV1UsersFollowersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/followers`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          status: queryArg.status,
        },
      }),
    }),
    getApiV1UsersRelationships: build.query<
      GetApiV1UsersRelationshipsApiResponse,
      GetApiV1UsersRelationshipsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/relationships`,
        params: { targets: queryArg.targets },
      }),
    }),
    getApiV1UsersRenameHistory: build.query<
      GetApiV1UsersRenameHistoryApiResponse,
      GetApiV1UsersRenameHistoryApiArg
    >({
      query: () => ({ url: `/api/v1/users/rename-history` }),
    }),
    getApiV1UsersByUserId: build.query<
      GetApiV1UsersByUserIdApiResponse,
      GetApiV1UsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.userId}`,
        params: { full: queryArg.full, htmlContent: queryArg.htmlContent },
      }),
    }),
    putApiV1UsersByUserId: build.mutation<
      PutApiV1UsersByUserIdApiResponse,
      PutApiV1UsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.userUpdate,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    deleteApiV1UsersByUserIdDelete: build.mutation<
      DeleteApiV1UsersByUserIdDeleteApiResponse,
      DeleteApiV1UsersByUserIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.userId}/delete`,
        method: 'DELETE',
      }),
    }),
    getApiV1UsersByUserIdFollowers: build.query<
      GetApiV1UsersByUserIdFollowersApiResponse,
      GetApiV1UsersByUserIdFollowersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.userId}/followers`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    getApiV1UsersByUserIdFollowing: build.query<
      GetApiV1UsersByUserIdFollowingApiResponse,
      GetApiV1UsersByUserIdFollowingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.userId}/following`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    putApiV1UsersByUserIdMedia: build.mutation<
      PutApiV1UsersByUserIdMediaApiResponse,
      PutApiV1UsersByUserIdMediaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.userId}/media`,
        method: 'PUT',
        body: queryArg.userUpdateMedia,
        params: { htmlContent: queryArg.htmlContent },
      }),
    }),
    putApiV1UsersByUserIdState: build.mutation<
      PutApiV1UsersByUserIdStateApiResponse,
      PutApiV1UsersByUserIdStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.userId}/state`,
        method: 'PUT',
        body: queryArg.userState,
      }),
    }),
    postFileUpload: build.mutation<
      PostFileUploadApiResponse,
      PostFileUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/file-upload`,
        method: 'POST',
        body: queryArg.multipartFormDataInput,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type PostApiV1AccountsAuthenticateApiResponse =
  /** status 200 Account, all account users and session key */ AccountWithUserAndToken
export type PostApiV1AccountsAuthenticateApiArg = {
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  authRequest: AuthRequest
}
export type PutApiV1AccountsChangeNameApiResponse = /** status 200 OK */ void
export type PutApiV1AccountsChangeNameApiArg = {
  /** id */
  id?: TypeId & (any | null)
  /** name */
  name: string
  /** username */
  username?: string | null
}
export type DeleteApiV1AccountsDeleteApiResponse = /** status 200 OK */ void
export type DeleteApiV1AccountsDeleteApiArg = void
export type PutApiV1AccountsEmailChangeByHashApiResponse =
  /** status 200 OK */ void
export type PutApiV1AccountsEmailChangeByHashApiArg = {
  hash: string
}
export type PutApiV1AccountsEmailConfirmChangeByHashApiResponse =
  /** status 200 OK */ void
export type PutApiV1AccountsEmailConfirmChangeByHashApiArg = {
  hash: string
  emailChangeConfirm: EmailChangeConfirm
}
export type PutApiV1AccountsEmailRequestChangeApiResponse =
  /** status 200 OK */ void
export type PutApiV1AccountsEmailRequestChangeApiArg = void
export type PutApiV1AccountsPasswordConfirmChangeByHashApiResponse =
  /** status 200 OK */ void
export type PutApiV1AccountsPasswordConfirmChangeByHashApiArg = {
  hash: string
  passwordUpdate: PasswordUpdate
}
export type PostApiV1AccountsPasswordConfirmByHashApiResponse =
  /** status 200 OK */ void
export type PostApiV1AccountsPasswordConfirmByHashApiArg = {
  hash: string
  pwdRecoveryUpdate: PwdRecoveryUpdate
}
export type PutApiV1AccountsPasswordRequestChangeApiResponse = unknown
export type PutApiV1AccountsPasswordRequestChangeApiArg = void
export type PostApiV1AccountsPasswordResetApiResponse = unknown
export type PostApiV1AccountsPasswordResetApiArg = {
  pwdRecoveryRequest: PwdRecoveryRequest
}
export type GetApiV1AccountsProfileApiResponse =
  /** status 200 Account and users */ AccountWithUser
export type GetApiV1AccountsProfileApiArg = {
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PostApiV1AccountsRegisterApiResponse =
  /** status 200 Account, users and session key */ AccountWithUserAndToken
export type PostApiV1AccountsRegisterApiArg = {
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  accountCreate: AccountCreate
}
export type PostApiV1AccountsReselectUserApiResponse =
  /** status 200 Account, users and session key */ AccountWithUserAndToken
export type PostApiV1AccountsReselectUserApiArg = {
  /** User id */
  userId?: TypeId & (any | null)
  /** Username */
  userName?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1AccountsByIdApiResponse =
  /** status 200 Account and users */ AccountWithUser
export type GetApiV1AccountsByIdApiArg = {
  /** User id */
  id: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PutApiV1AdminChangeRoleApiResponse =
  /** status 200 OK */ AccountWithUser
export type PutApiV1AdminChangeRoleApiArg = {
  /** id */
  id?: TypeId & (any | null)
  /** role */
  role: AccountRole
  /** username */
  username?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type DeleteApiV1AdminDeleteApiResponse = /** status 200 OK */ void
export type DeleteApiV1AdminDeleteApiArg = {
  /** Email of the account to delete */
  email?: string | null
  /** Id of the account to delete */
  id?: TypeId & (any | null)
  /** Username of the account to delete */
  username?: string | null
}
export type GetApiV1AdminListApiResponse =
  /** status 200 Returns accounts by given criteria */ AccountWithUser[]
export type GetApiV1AdminListApiArg = {
  accId?: TypeId & (any | null)
  email?: string | null
  name?: string | null
  role?: AccountRole & (any | null)
  userId?: TypeId & (any | null)
  username?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type DeleteApiV1AdminPurgeApiResponse = /** status 200 OK */ void
export type DeleteApiV1AdminPurgeApiArg = {
  /** Id of the account to purge */
  id: TypeId
}
export type GetApiV1AdminShowApiResponse =
  /** status 200 Account and users */ AccountWithUser
export type GetApiV1AdminShowApiArg = {
  /** Email */
  email?: string | null
  /** Account id */
  id?: TypeId & (any | null)
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1AdminShowUserApiResponse =
  /** status 200 User info */ AccountWithUser
export type GetApiV1AdminShowUserApiArg = {
  /** User id */
  id?: TypeId & (any | null)
  /** Username */
  username?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PostApiV1AppsApiResponse = /** status 200 OK */ Application
export type PostApiV1AppsApiArg = {
  /** Enable installed app after successful installation */
  enable?: boolean | null
  body: {
    file: Blob
  }
}
export type GetApiV1AppsEnabledApiResponse = /** status 200 OK */ Application[]
export type GetApiV1AppsEnabledApiArg = void
export type GetApiV1AppsFeaturedApiResponse =
  /** status 200 OK */ ApplicationWithPin[]
export type GetApiV1AppsFeaturedApiArg = void
export type GetApiV1AppsInstalledApiResponse =
  /** status 200 OK */ Application[]
export type GetApiV1AppsInstalledApiArg = void
export type GetApiV1AppsPinnedApiResponse =
  /** status 200 OK */ ApplicationWithPin[]
export type GetApiV1AppsPinnedApiArg = void
export type GetApiV1AppsUserApiResponse =
  /** status 200 OK */ ApplicationWithPin[]
export type GetApiV1AppsUserApiArg = void
export type PutApiV1AppsUserByAppIdApiResponse =
  /** status 200 Returns app with new pin order if pin was requested */ ApplicationWithPin
export type PutApiV1AppsUserByAppIdApiArg = {
  appId: TypeId
  /** If true pin the app to the toolbar */
  pin?: boolean | null
}
export type DeleteApiV1AppsUserByAppIdApiResponse = /** status 200 OK */ void
export type DeleteApiV1AppsUserByAppIdApiArg = {
  appId: TypeId
}
export type DeleteApiV1AppsUserByAppIdPinApiResponse = /** status 200 OK */ void
export type DeleteApiV1AppsUserByAppIdPinApiArg = {
  appId: TypeId
}
export type PutApiV1AppsUserByAppIdReorderApiResponse =
  /** status 200 Return list of user's apps sorted by the new pin order */ ApplicationWithPin[]
export type PutApiV1AppsUserByAppIdReorderApiArg = {
  /** Id of an app which needs to be reordered */
  appId: TypeId
  /** If true the app will be placed before marker app, otherwise - after */
  before: boolean
  /** Id of an anchor app relative to which the app should be reordered */
  markerAppId: TypeId
}
export type GetApiV1AppsByAppIdApiResponse = /** status 200 OK */ Application
export type GetApiV1AppsByAppIdApiArg = {
  appId: TypeId
}
export type PutApiV1AppsByAppIdApiResponse = /** status 200 OK */ Application
export type PutApiV1AppsByAppIdApiArg = {
  appId: TypeId
  /** Enable installed app after successful installation */
  enable?: boolean | null
  body: {
    file: Blob
  }
}
export type DeleteApiV1AppsByAppIdApiResponse = /** status 200 OK */ void
export type DeleteApiV1AppsByAppIdApiArg = {
  appId: TypeId
}
export type PutApiV1AppsByAppIdDisableApiResponse = /** status 200 OK */ void
export type PutApiV1AppsByAppIdDisableApiArg = {
  appId: TypeId
}
export type PutApiV1AppsByAppIdEnableApiResponse = /** status 200 OK */ void
export type PutApiV1AppsByAppIdEnableApiArg = {
  appId: TypeId
}
export type PutApiV1AppsByAppIdFeaturedApiResponse =
  /** status 200 Return application with pinned order set */ ApplicationWithPin
export type PutApiV1AppsByAppIdFeaturedApiArg = {
  appId: TypeId
}
export type DeleteApiV1AppsByAppIdFeaturedApiResponse =
  /** status 200 OK */ void
export type DeleteApiV1AppsByAppIdFeaturedApiArg = {
  appId: TypeId
}
export type PutApiV1AppsByAppIdFeaturedReorderApiResponse =
  /** status 200 Return list of featured apps sorted by the new pin order */ ApplicationWithPin[]
export type PutApiV1AppsByAppIdFeaturedReorderApiArg = {
  /** Id of an app which needs to be reordered */
  appId: TypeId
  /** If true the app will be placed before marker app, otherwise - after */
  before: boolean
  /** Id of an anchor app relative to which the app should be reordered */
  markerAppId: TypeId
}
export type GetApiV1AppsByAppIdStatsApiResponse =
  /** status 200 OK */ ApplicationWithStats
export type GetApiV1AppsByAppIdStatsApiArg = {
  appId: TypeId
}
export type PutApiV1AppsByAppIdAndTypeDefaultApiResponse =
  /** status 200 OK */ void
export type PutApiV1AppsByAppIdAndTypeDefaultApiArg = {
  appId: TypeId
  type: DataType
}
export type PutApiV1AppsByAppIdAndTypeUserDefaultApiResponse =
  /** status 200 OK */ void
export type PutApiV1AppsByAppIdAndTypeUserDefaultApiArg = {
  appId: TypeId
  type: DataType
}
export type DeleteApiV1AppsByAppIdAndTypeUserDefaultApiResponse =
  /** status 200 OK */ void
export type DeleteApiV1AppsByAppIdAndTypeUserDefaultApiArg = {
  appId: TypeId
  type: DataType
}
export type GetApiV1BlockApiResponse = /** status 200 OK */ User[]
export type GetApiV1BlockApiArg = {
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
}
export type PostApiV1BlockByUserIdApiResponse = /** status 200 OK */ void
export type PostApiV1BlockByUserIdApiArg = {
  userId: TypeId
}
export type DeleteApiV1BlockByUserIdApiResponse = /** status 200 OK */ void
export type DeleteApiV1BlockByUserIdApiArg = {
  userId: TypeId
}
export type GetApiV1CustomEmojisApiResponse =
  /** status 200 Array of json objects with the emoji data */ Emoji[]
export type GetApiV1CustomEmojisApiArg = {
  /** Full shortcode of an emoji. You can provide multiple shortcodes as comma-separated values */
  shortcode: string[]
}
export type PostApiV1CustomEmojisApiResponse =
  /** status 200 json object with the emoji data */ Emoji
export type PostApiV1CustomEmojisApiArg = {
  /** Should existing emojis be overwritten if shortcode matches */
  overwrite?: boolean
  /** Json object with the emoji data: shortcode, category, url, staticUrl, visibleInPicker */
  emoji: Emoji
}
export type DeleteApiV1CustomEmojisApiResponse =
  /** status 200 Number of deleted emojis */ Blob
export type DeleteApiV1CustomEmojisApiArg = {
  /** You can provide multiple shortcodes as comma-separated values */
  shortcode?: string[] | null
  /** You can provide multiple categories as comma-separated values */
  category?: string[] | null
}
export type GetApiV1CustomEmojisExportApiResponse =
  /** status 200 Zip file with the emojis */ Blob
export type GetApiV1CustomEmojisExportApiArg = {
  /** Category of the emojis to export */
  category?: string
}
export type PostApiV1CustomEmojisImportApiResponse =
  /** status 200 Array of json objects with the emoji data */ Emoji[]
export type PostApiV1CustomEmojisImportApiArg = {
  /** Whether the imported emojis should be visible in the picker */
  visible: boolean
  /** Category of the imported emojis */
  category: string
  /** Should existing emojis be overwritten if any of the shortcodes match */
  overwrite?: string
  /** Zip file with the emojis. */
  body: {
    file: Blob
  }
}
export type GetApiV1CustomEmojisSearchApiResponse =
  /** status 200 Array of json json objects with the emoji data */ Emoji[]
export type GetApiV1CustomEmojisSearchApiArg = {
  /** You can provide multiple shortcodes prefixes as comma-separated values */
  shortcode: string[]
}
export type PostApiV1CustomEmojisUploadApiResponse =
  /** status 200 json object with the emoji data */ Emoji
export type PostApiV1CustomEmojisUploadApiArg = {
  /** Shortcode of the emoji */
  shortcode: string
  /** Whether the emoji should be visible in the picker */
  visible: boolean
  /** Category of the emoji */
  category: string
  /** Should existing emojis be overwritten if shortcode matches */
  overwrite?: boolean
  /** Emoji file to upload. Should be a single image file. File name and extension do not matter, the content of the file will be checked. */
  body: object
}
export type GetApiV1EmployerProfilesApiResponse =
  /** status 200 The list of the Profiles */ EmployerProfile
export type GetApiV1EmployerProfilesApiArg = void
export type PutApiV1EmployerProfilesApiResponse =
  /** status 200 The just updated EmployerProfile object */ EmployerProfile
export type PutApiV1EmployerProfilesApiArg = {
  employerProfile: EmployerProfile
}
export type PostApiV1EmployerProfilesApiResponse =
  /** status 200 The newly created EmployerProfile object */ EmployerProfile
export type PostApiV1EmployerProfilesApiArg = {
  employerProfile: EmployerProfile
}
export type GetApiV1EmployerProfilesUserByUserIdApiResponse =
  /** status 200 Employer Profile */ EmployerProfile
export type GetApiV1EmployerProfilesUserByUserIdApiArg = {
  /** User Id, if omitted the caller's User Id is assumed */
  userId: TypeId & (any | null)
}
export type GetApiV1EmployerProfilesByProfileIdApiResponse =
  /** status 200 Employer Profile */ EmployerProfile
export type GetApiV1EmployerProfilesByProfileIdApiArg = {
  /** Profile id */
  profileId: TypeId
}
export type DeleteApiV1EmployerProfilesByProfileIdApiResponse =
  /** status 200 The string containing `Deleted` or `NOT Deleted`, the latter should not happen */ string
export type DeleteApiV1EmployerProfilesByProfileIdApiArg = {
  /** Profile id */
  profileId: TypeId
}
export type PutApiV1EmployerProfilesByProfileIdCurrentApiResponse =
  /** status 200 The string OK */ string
export type PutApiV1EmployerProfilesByProfileIdCurrentApiArg = {
  /** Profile id */
  profileId: TypeId
}
export type GetApiV1InstanceApiResponse =
  /** status 200 Instance info */ Instance
export type GetApiV1InstanceApiArg = void
export type GetApiV1InstanceDomainsApiResponse =
  /** status 200 Domains list */ string[]
export type GetApiV1InstanceDomainsApiArg = {
  /** domain */
  domain: string
  /** limit */
  limit?: number | null
  /** protocol */
  protocol: ApProtocol
}
export type GetApiV1InstanceExtendedDescriptionApiResponse =
  /** status 200 Extended Instance info */ Instance
export type GetApiV1InstanceExtendedDescriptionApiArg = void
export type GetApiV1JobApplicationsApiResponse =
  /** status 200 List of Job Applications */ JobApplication
export type GetApiV1JobApplicationsApiArg = void
export type PutApiV1JobApplicationsApiResponse =
  /** status 200 The just updated JobApplication object */ JobApplication
export type PutApiV1JobApplicationsApiArg = {
  jobApplication: JobApplication
}
export type PostApiV1JobApplicationsApiResponse =
  /** status 200 The newly created JobApplication object */ JobApplication
export type PostApiV1JobApplicationsApiArg = {
  jobApplication: JobApplication
}
export type GetApiV1JobApplicationsByApplicationIdApiResponse =
  /** status 200 Job Application */ JobApplication
export type GetApiV1JobApplicationsByApplicationIdApiArg = {
  /** Job Application id */
  applicationId: TypeId
}
export type DeleteApiV1JobApplicationsByApplicationIdApiResponse =
  /** status 200 The just updated JobPosting object */ JobApplication
export type DeleteApiV1JobApplicationsByApplicationIdApiArg = {
  /** Job Application id */
  applicationId: TypeId
}
export type GetApiV1JobPostingsApiResponse =
  /** status 200 List of Job Postings */ JobPosting
export type GetApiV1JobPostingsApiArg = {
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PutApiV1JobPostingsApiResponse =
  /** status 200 The just updated JobPosting object */ JobPosting
export type PutApiV1JobPostingsApiArg = {
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  jobPosting: JobPosting
}
export type PostApiV1JobPostingsApiResponse =
  /** status 200 The newly created JobPosting object */ JobPosting
export type PostApiV1JobPostingsApiArg = {
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  jobPosting: JobPosting
}
export type GetApiV1JobPostingsByPostingIdApiResponse =
  /** status 200 Job Posting */ JobPosting
export type GetApiV1JobPostingsByPostingIdApiArg = {
  /** Job Posting id */
  postingId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type DeleteApiV1JobPostingsByPostingIdApiResponse =
  /** status 200 The just updated JobPosting object */ JobPosting
export type DeleteApiV1JobPostingsByPostingIdApiArg = {
  /** Job Posting id */
  postingId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1JobProfilesApiResponse =
  /** status 200 The list of the Profiles */ EmployeeProfile
export type GetApiV1JobProfilesApiArg = void
export type PutApiV1JobProfilesApiResponse =
  /** status 200 The just updated EmployeeProfile object */ EmployeeProfile
export type PutApiV1JobProfilesApiArg = {
  employeeProfile: EmployeeProfile
}
export type PostApiV1JobProfilesApiResponse =
  /** status 200 The newly created EmployeeProfile object */ EmployeeProfile
export type PostApiV1JobProfilesApiArg = {
  employeeProfile: EmployeeProfile
}
export type GetApiV1JobProfilesUserByUserIdApiResponse =
  /** status 200 Employee Profile */ EmployeeProfile
export type GetApiV1JobProfilesUserByUserIdApiArg = {
  /** User Id, if omitted the caller's User Id is assumed */
  userId: TypeId & (any | null)
}
export type GetApiV1JobProfilesByProfileIdApiResponse =
  /** status 200 Employee Profile */ EmployeeProfile
export type GetApiV1JobProfilesByProfileIdApiArg = {
  /** Profile id */
  profileId: TypeId
}
export type DeleteApiV1JobProfilesByProfileIdApiResponse =
  /** status 200 The string containing `Deleted` or `NOT Deleted`, the latter should not happen */ string
export type DeleteApiV1JobProfilesByProfileIdApiArg = {
  /** Profile id */
  profileId: TypeId
}
export type PutApiV1JobProfilesByProfileIdCurrentApiResponse =
  /** status 200 The string OK */ string
export type PutApiV1JobProfilesByProfileIdCurrentApiArg = {
  /** Profile id */
  profileId: TypeId
}
export type GetApiV1JobsListStatsApiResponse = unknown
export type GetApiV1JobsListStatsApiArg = {
  queue: string
}
export type GetApiV1JobsListStatsAllApiResponse = unknown
export type GetApiV1JobsListStatsAllApiArg = void
export type GetApiV1JobsListsApiResponse = unknown
export type GetApiV1JobsListsApiArg = {
  listKind: string
  queue: string
}
export type GetApiV1JobsStatsApiResponse = unknown
export type GetApiV1JobsStatsApiArg = void
export type GetApiV1JobsStatsPerDayApiResponse = unknown
export type GetApiV1JobsStatsPerDayApiArg = {
  lastDays?: number
}
export type GetApiV1ListsApiResponse = /** status 200 OK */ UsersList[]
export type GetApiV1ListsApiArg = void
export type PostApiV1ListsApiResponse = /** status 200 OK */ UsersList
export type PostApiV1ListsApiArg = {
  userListCreate: UserListCreate
}
export type GetApiV1ListsByListIdApiResponse = /** status 200 OK */ UsersList
export type GetApiV1ListsByListIdApiArg = {
  listId: TypeId
}
export type PutApiV1ListsByListIdApiResponse = /** status 200 OK */ UsersList
export type PutApiV1ListsByListIdApiArg = {
  listId: TypeId
  userListCreate: UserListCreate
}
export type DeleteApiV1ListsByListIdApiResponse = /** status 200 OK */ string
export type DeleteApiV1ListsByListIdApiArg = {
  listId: TypeId
}
export type GetApiV1ListsByListIdUsersApiResponse = /** status 200 OK */ User[]
export type GetApiV1ListsByListIdUsersApiArg = {
  listId: TypeId
}
export type PostApiV1ListsByListIdUsersAndUserIdApiResponse =
  /** status 200 OK */ UsersList
export type PostApiV1ListsByListIdUsersAndUserIdApiArg = {
  listId: TypeId
  userId: TypeId
}
export type DeleteApiV1ListsByListIdUsersAndUserIdApiResponse =
  /** status 200 OK */ UsersList
export type DeleteApiV1ListsByListIdUsersAndUserIdApiArg = {
  listId: TypeId
  userId: TypeId
}
export type GetApiV1MuteApiResponse = /** status 200 OK */ User[]
export type GetApiV1MuteApiArg = {
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
}
export type PostApiV1MuteByUserIdApiResponse = /** status 200 OK */ void
export type PostApiV1MuteByUserIdApiArg = {
  userId: TypeId
}
export type DeleteApiV1MuteByUserIdApiResponse = /** status 200 OK */ void
export type DeleteApiV1MuteByUserIdApiArg = {
  userId: TypeId
}
export type GetApiV1NotesApiResponse =
  /** status 200 Notes have been fetched successfully */ UserNote[]
export type GetApiV1NotesApiArg = {
  userIds: TypeId[]
}
export type PutApiV1NotesApiResponse =
  /** status 200 Note has been updated successfully */ UserNote
export type PutApiV1NotesApiArg = {
  noteUpdate: NoteUpdate
}
export type DeleteApiV1NotesByUserIdApiResponse =
  /** status 200 Note has been deleted successfully */ void
export type DeleteApiV1NotesByUserIdApiArg = {
  userId: TypeId
}
export type GetApiV1NotificationsApiResponse =
  /** status 200 Notifications */ Notification[]
export type GetApiV1NotificationsApiArg = {
  excludeTypes?: NotificationType[] | null
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
  status?: NotificationStatus & (any | null)
  types?: NotificationType[] | null
}
export type GetApiV1NotificationsCountApiResponse = /** status 200 OK */ number
export type GetApiV1NotificationsCountApiArg = {
  excludeTypes?: NotificationType[] | null
  status?: NotificationStatus & (any | null)
  types?: NotificationType[] | null
}
export type PostApiV1NotificationsMarkAllApiResponse =
  /** status 200 Number of notifications changed */ number
export type PostApiV1NotificationsMarkAllApiArg = {
  status: NotificationStatus
}
export type GetApiV1NotificationsByIdApiResponse =
  /** status 200 Notification */ Notification
export type GetApiV1NotificationsByIdApiArg = {
  /** Notification id */
  id: TypeId
}
export type PostApiV1NotificationsByIdMarkApiResponse =
  /** status 200 Notification */ Notification
export type PostApiV1NotificationsByIdMarkApiArg = {
  /** Notification id */
  id: TypeId
  status: NotificationStatus
}
export type GetApiV1PostsApiResponse = /** status 200 OK */ Post[]
export type GetApiV1PostsApiArg = {
  /** Include follow status */
  includeFollowStatus?: boolean | null
  limit?: number | null
  /** Limit first level comment paging count */
  limitFirstLevelComments?: number | null
  /** Limit second level comment paging count */
  limitSecondLevelComments?: number | null
  /** List posts by a user list */
  listId?: TypeId & (any | null)
  /** Max comment level depth */
  maxLevel?: number | null
  /** Min comment level depth */
  minLevel?: number | null
  offset?: number | null
  /** Filter posts by protocol */
  protocol: ApProtocol & (any | null)
  /** Return reposts only */
  repostsOnly?: boolean | null
  /** Search posts by text */
  search?: string | null
  sortBy?: string | null
  sortOrder?: string | null
  /** Search posts by a tag */
  tag?: string | null
  /** List specific user's posts */
  userId?: TypeId & (any | null)
  /** List specific user's posts */
  username?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PostApiV1PostsApiResponse =
  /** status 200 Post object just created */ Post
export type PostApiV1PostsApiArg = {
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  createPost: CreatePost
}
export type GetApiV1PostsFollowingApiResponse = /** status 200 OK */ Post[]
export type GetApiV1PostsFollowingApiArg = {
  limit?: number | null
  offset?: number | null
  /** Filter posts by protocol */
  protocol: ApProtocol & (any | null)
  sortBy?: string | null
  sortOrder?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1PostsLikedApiResponse = /** status 200 OK */ Post[]
export type GetApiV1PostsLikedApiArg = {
  limit?: number | null
  offset?: number | null
  /** Filter posts by protocol */
  protocol: ApProtocol & (any | null)
  sortBy?: string | null
  sortOrder?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1PostsMentionedApiResponse = /** status 200 OK */ Post[]
export type GetApiV1PostsMentionedApiArg = {
  limit?: number | null
  offset?: number | null
  /** Filter posts by DIRECT only */
  private?: boolean | null
  /** Filter posts by protocol */
  protocol: ApProtocol & (any | null)
  sortBy?: string | null
  sortOrder?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1PostsMyApiResponse = /** status 200 OK */ Post[]
export type GetApiV1PostsMyApiArg = {
  /** Return bookmarked only */
  bookmarkedOnly?: boolean | null
  /** Include comments */
  includeComments?: boolean | null
  limit?: number | null
  offset?: number | null
  /** Return reposts only */
  repostsOnly?: boolean | null
  sortBy?: string | null
  sortOrder?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1PostsRepliesApiResponse = /** status 200 OK */ Post[]
export type GetApiV1PostsRepliesApiArg = {
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
  /** List specific user's posts */
  userId?: TypeId & (any | null)
  /** List specific user's posts */
  username?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1PostsByPostIdApiResponse = /** status 200 OK */ Post
export type GetApiV1PostsByPostIdApiArg = {
  postId: TypeId
  /** Limit first level comment paging count */
  limitFirstLevelComments?: number | null
  /** Limit second level comment paging count */
  limitSecondLevelComments?: number | null
  /** Max comment level depth */
  maxLevel?: number | null
  /** Min comment level depth */
  minLevel?: number | null
  /** Load post with comments */
  withComments?: boolean | null
  /** If comment, load it with parents.
                   In such a case returns root with request `postId` comment inside replies */
  withParents?: boolean | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PutApiV1PostsByPostIdApiResponse = /** status 200 OK */ Post
export type PutApiV1PostsByPostIdApiArg = {
  postId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  createPost: CreatePost
}
export type DeleteApiV1PostsByPostIdApiResponse =
  /** status 200 Post has been deleted successfully */ void
export type DeleteApiV1PostsByPostIdApiArg = {
  postId: TypeId
}
export type PostApiV1PostsByPostIdBookmarkApiResponse =
  /** status 200 OK */ void
export type PostApiV1PostsByPostIdBookmarkApiArg = {
  postId: TypeId
}
export type DeleteApiV1PostsByPostIdBookmarkApiResponse =
  /** status 200 OK */ void
export type DeleteApiV1PostsByPostIdBookmarkApiArg = {
  postId: TypeId
}
export type GetApiV1PostsByPostIdCommentsApiResponse =
  /** status 200 OK */ Post[]
export type GetApiV1PostsByPostIdCommentsApiArg = {
  postId: TypeId
  limit?: number | null
  /** Limit first level comment paging count */
  limitFirstLevelComments?: number | null
  /** Limit second level comment paging count */
  limitSecondLevelComments?: number | null
  maxLevel?: number | null
  minLevel?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PostApiV1PostsByPostIdCommentsApiResponse =
  /** status 200 OK */ Post
export type PostApiV1PostsByPostIdCommentsApiArg = {
  postId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  createPost: CreatePost
}
export type PutApiV1PostsByPostIdCommentsAndCommentIdApiResponse =
  /** status 200 OK */ Post
export type PutApiV1PostsByPostIdCommentsAndCommentIdApiArg = {
  commentId: TypeId
  postId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  createPost: CreatePost
}
export type DeleteApiV1PostsByPostIdCommentsAndCommentIdApiResponse =
  /** status 200 Comment has been deleted successfully */ void
export type DeleteApiV1PostsByPostIdCommentsAndCommentIdApiArg = {
  commentId: TypeId
  postId: TypeId
}
export type GetApiV1PostsByPostIdEditsApiResponse =
  /** status 200 OK */ PostEdit[]
export type GetApiV1PostsByPostIdEditsApiArg = {
  postId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1PostsByPostIdLikesApiResponse = /** status 200 OK */ Like[]
export type GetApiV1PostsByPostIdLikesApiArg = {
  postId: TypeId
}
export type PostApiV1PostsByPostIdLikesApiResponse =
  /** status 200 New number of likes */ number
export type PostApiV1PostsByPostIdLikesApiArg = {
  postId: TypeId
}
export type DeleteApiV1PostsByPostIdLikesApiResponse =
  /** status 200 New number of likes */ number
export type DeleteApiV1PostsByPostIdLikesApiArg = {
  postId: TypeId
}
export type PostApiV1PostsByPostIdRepostApiResponse =
  /** status 200 Post object just created */ Post
export type PostApiV1PostsByPostIdRepostApiArg = {
  postId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  createPost: CreatePost
}
export type GetApiV1ReportsApiResponse = /** status 200 OK */ Report[]
export type GetApiV1ReportsApiArg = {
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
}
export type PostApiV1ReportsApiResponse = /** status 200 OK */ void
export type PostApiV1ReportsApiArg = {
  createReport: CreateReport
}
export type GetApiV1ReportsByReportIdApiResponse = /** status 200 OK */ Report
export type GetApiV1ReportsByReportIdApiArg = {
  reportId: TypeId
}
export type PostApiV1ReportsByReportIdAddNoteApiResponse =
  /** status 200 OK */ Report
export type PostApiV1ReportsByReportIdAddNoteApiArg = {
  reportId: TypeId
  body: string
}
export type PostApiV1ReportsByReportIdAssignToSelfApiResponse =
  /** status 200 OK */ Report
export type PostApiV1ReportsByReportIdAssignToSelfApiArg = {
  reportId: TypeId
}
export type PostApiV1ReportsByReportIdRemoveNoteApiResponse =
  /** status 200 OK */ Report
export type PostApiV1ReportsByReportIdRemoveNoteApiArg = {
  reportId: TypeId
  /** Index of the report in the list */
  index: number
}
export type PostApiV1ReportsByReportIdReopenApiResponse =
  /** status 200 OK */ Report
export type PostApiV1ReportsByReportIdReopenApiArg = {
  reportId: TypeId
}
export type PostApiV1ReportsByReportIdResolveApiResponse =
  /** status 200 OK */ Report
export type PostApiV1ReportsByReportIdResolveApiArg = {
  reportId: TypeId
}
export type PostApiV1ReportsByReportIdUnassignApiResponse =
  /** status 200 OK */ Report
export type PostApiV1ReportsByReportIdUnassignApiArg = {
  reportId: TypeId
}
export type GetApiV1RulesApiResponse =
  /** status 200 List of all rules */ Rule[]
export type GetApiV1RulesApiArg = void
export type PutApiV1RulesApiResponse =
  /** status 200 Updated Rule object */ Rule
export type PutApiV1RulesApiArg = {
  rule: Rule
}
export type PostApiV1RulesApiResponse =
  /** status 200 Created Rule object */ Rule
export type PostApiV1RulesApiArg = {
  hint: string
  ordering?: number
  text: string
}
export type DeleteApiV1RulesByIdApiResponse = unknown
export type DeleteApiV1RulesByIdApiArg = {
  id: string
}
export type GetApiV1SearchApiResponse =
  /** status 200 Search result */ SearchResult
export type GetApiV1SearchApiArg = {
  /** Only include specific types of results. Like posts, users, tags */
  filter?: string[] | null
  /** Only include accounts that follows the user */
  followers?: boolean | null
  /** Only include accounts that the user is following */
  following?: boolean | null
  limit?: number | null
  offset?: number | null
  /** Filter users by protocol */
  protocol: ApProtocol
  /** Search query */
  q?: string | null
  /** Attempt to resolve remote users (WebFinger lookup) */
  resolve?: boolean
  sortBy?: string | null
  sortOrder?: string | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type GetApiV1SettingsByNamespaceApiResponse =
  /** status 200 Subset of platform settings as a json */ Blob
export type GetApiV1SettingsByNamespaceApiArg = {
  /** platform for platform settings or userId for user settings */
  namespace: string
  /** An optional query parameter with the setting name or comma-separated names.You will get all settings if this parameter is omitted. */
  keys?: string[] | null
}
export type PutApiV1SettingsByNamespaceApiResponse =
  /** status 200 Number of updated settings */ Blob
export type PutApiV1SettingsByNamespaceApiArg = {
  /** platform for platform settings or userId for user settings */
  namespace: string
  /** Json object with settings names as keys and corresponding values.Json object can have nested json objects. */
  jsonNode: JsonNode
}
export type DeleteApiV1SettingsByNamespaceApiResponse =
  /** status 200 Number of deleted settings */ Blob
export type DeleteApiV1SettingsByNamespaceApiArg = {
  /** platform for platform settings or userId for user settings */
  namespace: string
  /** At least one query parameter with the setting name should be provided. You can provide multiple keys as comma-separated values */
  keys: string[]
}
export type GetApiV1SettingsByNamespaceJsonApiResponse =
  /** status 200 Json object with settings names as keys and corresponding values. */ Blob
export type GetApiV1SettingsByNamespaceJsonApiArg = {
  /** platform for platform settings or userId for user settings */
  namespace: string
  /** An optional query parameter with the setting name or comma-separated names.You will get all settings if this parameter is omitted. */
  keys?: string[] | null
}
export type GetApiV1TagsFeaturedApiResponse = /** status 200 OK */ Tag[]
export type GetApiV1TagsFeaturedApiArg = {
  /** List specific user's featured tags */
  userId?: TypeId & (any | null)
}
export type PostApiV1TagsFeaturedByNameApiResponse = /** status 200 OK */ void
export type PostApiV1TagsFeaturedByNameApiArg = {
  name: string
}
export type DeleteApiV1TagsFeaturedByNameApiResponse = /** status 200 OK */ void
export type DeleteApiV1TagsFeaturedByNameApiArg = {
  name: string
}
export type PostApiV1TagsFollowByNameApiResponse = /** status 200 OK */ void
export type PostApiV1TagsFollowByNameApiArg = {
  name: string
  /** Tags protocol */
  protocol: ApProtocol
}
export type GetApiV1TagsFollowedApiResponse = /** status 200 OK */ Tag[]
export type GetApiV1TagsFollowedApiArg = {
  /** Tags protocol */
  protocol: ApProtocol
}
export type PostApiV1TagsUnfollowByNameApiResponse = /** status 200 OK */ void
export type PostApiV1TagsUnfollowByNameApiArg = {
  name: string
  /** Tags protocol */
  protocol: ApProtocol
}
export type GetApiV1TagsByNameApiResponse = /** status 200 OK */ Tag
export type GetApiV1TagsByNameApiArg = {
  name: string
  /** Filter history since. 2 weeks by default */
  historySince?: LocalDate & (any | null)
  /** Tags protocol */
  protocol: ApProtocol
}
export type PostApiV1UploadsApiResponse =
  /** status 200 Upload successfully created */ Result
export type PostApiV1UploadsApiArg = {
  request1: Request1
}
export type DeleteApiV1UploadsBatchApiResponse =
  /** status 200 Delete multiple upload is successful */ Upload
export type DeleteApiV1UploadsBatchApiArg = {
  body: TypeId[]
}
export type PostApiV1UploadsListApiResponse =
  /** status 200 Operation is successful */ Upload[]
export type PostApiV1UploadsListApiArg = {
  request: Request
}
export type GetApiV1UploadsUsagesApiResponse =
  /** status 200 Json with usages for specified upload ids */ UploadUsages
export type GetApiV1UploadsUsagesApiArg = {
  ids?: string[] | null
}
export type GetApiV1UploadsByIdApiResponse =
  /** status 200 Gets upload data with file info */ Upload
export type GetApiV1UploadsByIdApiArg = {
  id: TypeId
}
export type DeleteApiV1UploadsByIdApiResponse =
  /** status 200 Delete upload is successful */ Upload
export type DeleteApiV1UploadsByIdApiArg = {
  id: TypeId
}
export type PutApiV1UploadsByIdMetaApiResponse =
  /** status 200 Operation is successful */ Upload
export type PutApiV1UploadsByIdMetaApiArg = {
  id: TypeId
  body: {
    [key: string]: any
  }
}
export type PostApiV1UploadsByIdMetaApiResponse =
  /** status 200 Operation is successful */ Upload
export type PostApiV1UploadsByIdMetaApiArg = {
  id: TypeId
  body: {
    [key: string]: any
  }
}
export type PutApiV1UploadsByIdTagsApiResponse =
  /** status 200 Operation is successful */ Upload
export type PutApiV1UploadsByIdTagsApiArg = {
  id: TypeId
  body: UploadsTags[]
}
export type PostApiV1UploadsByIdTagsApiResponse =
  /** status 200 Operation is successful */ Upload
export type PostApiV1UploadsByIdTagsApiArg = {
  id: TypeId
  body: UploadsTags[]
}
export type PostApiV1UploadsByIdThumbnailApiResponse =
  /** status 200 Operation is successful */ Upload
export type PostApiV1UploadsByIdThumbnailApiArg = {
  id: TypeId
  request2: Request2
}
export type GetApiV1UserDomainBlockApiResponse = /** status 200 OK */ string[]
export type GetApiV1UserDomainBlockApiArg = void
export type PostApiV1UserDomainBlockByDomainApiResponse =
  /** status 200 OK */ void
export type PostApiV1UserDomainBlockByDomainApiArg = {
  domain: string
}
export type DeleteApiV1UserDomainBlockByDomainApiResponse =
  /** status 200 OK */ void
export type DeleteApiV1UserDomainBlockByDomainApiArg = {
  domain: string
}
export type GetApiV1UsersApiResponse = /** status 200 OK */ User | UserFull
export type GetApiV1UsersApiArg = {
  /** If true return also summary, stats, header and fields. Default false. */
  full?: boolean | null
  username: string
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PostApiV1UsersApiResponse = /** status 200 OK */ UserFull
export type PostApiV1UsersApiArg = {
  /** Email of the account to add user to */
  email?: string | null
  accountId?: TypeId & (any | null)
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  /** Json object with the users data */
  userCreate: UserCreate
}
export type DeleteApiV1UsersApiResponse = /** status 200 User deleted */ User
export type DeleteApiV1UsersApiArg = {
  id?: TypeId & (any | null)
  name?: string | null
}
export type GetApiV1UsersConnectionsApiResponse = /** status 200 OK */ User[]
export type GetApiV1UsersConnectionsApiArg = {
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
  /** The target Follow status like: ACCEPTED/REJECTED/PENDING */
  status?: FollowingStatus & any
}
export type PostApiV1UsersFollowByUsernameApiResponse =
  /** status 200 OK */ void
export type PostApiV1UsersFollowByUsernameApiArg = {
  /** Username of the followee */
  username: string
  /** Followee's domain.  If omitted assumed to be local */
  domain?: string | null
  /** 'false' for regular Follow, 'true' for bidirectional Connect */
  mutual?: boolean
  /** http vs. https.  For testing ONLY */
  scheme?: string
}
export type PostApiV1UsersFollowByUsernameAcceptApiResponse =
  /** status 200 OK */ void
export type PostApiV1UsersFollowByUsernameAcceptApiArg = {
  /** The URI of the prospective follower */
  username: string
}
export type PostApiV1UsersFollowByUsernameRejectApiResponse =
  /** status 200 OK */ void
export type PostApiV1UsersFollowByUsernameRejectApiArg = {
  /** The URI of the prospective follower */
  username: string
}
export type PostApiV1UsersFollowByUsernameUndoApiResponse =
  /** status 200 OK */ void
export type PostApiV1UsersFollowByUsernameUndoApiArg = {
  /** Username of the followee */
  username: string
  /** Followee's domain.  If omitted assumed to be local */
  domain?: string | null
  /** 'false' for regular Follow, 'true' for bidirectional Connect */
  mutual?: boolean
  /** http vs. https.  For testing ONLY */
  scheme?: string
}
export type GetApiV1UsersFolloweesApiResponse = /** status 200 OK */ User[]
export type GetApiV1UsersFolloweesApiArg = {
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
  /** The target Follow status like: ACCEPTED/REJECTED/PENDING */
  status?: FollowingStatus & (any | null)
}
export type GetApiV1UsersFollowersApiResponse = /** status 200 OK */ User[]
export type GetApiV1UsersFollowersApiArg = {
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
  /** The target Follow status like: ACCEPTED/REJECTED/PENDING */
  status?: FollowingStatus & (any | null)
}
export type GetApiV1UsersRelationshipsApiResponse = /** status 200 OK */ {
  [key: string]: Relationship
}
export type GetApiV1UsersRelationshipsApiArg = {
  /** Target users to get relationship with */
  targets: TypeId[]
}
export type GetApiV1UsersRenameHistoryApiResponse =
  /** status 200 OK */ UsernameHistories
export type GetApiV1UsersRenameHistoryApiArg = void
export type GetApiV1UsersByUserIdApiResponse = /** status 200 OK */
  | User
  | UserFull
export type GetApiV1UsersByUserIdApiArg = {
  userId: TypeId
  /** If true return also summary, stats, header and fields. Default false. */
  full?: boolean | null
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
}
export type PutApiV1UsersByUserIdApiResponse = /** status 200 OK */ UserFull
export type PutApiV1UsersByUserIdApiArg = {
  userId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  userUpdate: UserUpdate
}
export type DeleteApiV1UsersByUserIdDeleteApiResponse =
  /** status 200 User has been deleted successfully */ void
export type DeleteApiV1UsersByUserIdDeleteApiArg = {
  userId: TypeId
}
export type GetApiV1UsersByUserIdFollowersApiResponse =
  /** status 200 List of followers as User objects. Or empty list if the user hides followers */ User[]
export type GetApiV1UsersByUserIdFollowersApiArg = {
  userId: TypeId
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
}
export type GetApiV1UsersByUserIdFollowingApiResponse =
  /** status 200 List of following as User objects. Or empty list if the user hides followings */ User[]
export type GetApiV1UsersByUserIdFollowingApiArg = {
  userId: TypeId
  limit?: number | null
  offset?: number | null
  sortBy?: string | null
  sortOrder?: string | null
}
export type PutApiV1UsersByUserIdMediaApiResponse =
  /** status 200 OK */ UserFull
export type PutApiV1UsersByUserIdMediaApiArg = {
  userId: TypeId
  /** Returns summary as html if true or original text if false. Applicable only to local posts or users. Default is true. */
  htmlContent?: boolean
  userUpdateMedia: UserUpdateMedia
}
export type PutApiV1UsersByUserIdStateApiResponse = /** status 200 OK */ void
export type PutApiV1UsersByUserIdStateApiArg = {
  userId: TypeId
  userState: UserState
}
export type PostFileUploadApiResponse = unknown
export type PostFileUploadApiArg = {
  multipartFormDataInput: MultipartFormDataInput
}
export type TypeId = string
export type AccountRole = 'ADMIN' | 'USER'
export type Instant = string
export type Account = {
  /** Internal account id */
  id: TypeId
  /** Account email */
  email: string
  password: string
  /** Account's role */
  role: AccountRole
  createdAt: Instant
  updatedAt: Instant
}
export type UserStats = {
  /** number of posts */
  posts: number
  /** number of followers */
  followers: number
  /** number of following */
  following: number
  /** privacy setting to hide followings */
  followingHidden: boolean
}
export type UploadType = 'VIDEO' | 'IMAGE' | 'AUDIO' | 'DOCUMENT' | 'OTHER'
export type Meta = {
  blurhash?: string | null
  name?: string | null
}
export type FileExtension =
  | 'MP4'
  | 'AVI'
  | 'MOV'
  | 'M3U8'
  | 'JPEG'
  | 'JPG'
  | 'PNG'
  | 'GIF'
  | 'MP3'
  | 'AAC'
  | 'PDF'
  | 'OTHER'
export type Meta1 = {
  /** Video duration in seconds */
  duration?: number | null
  /** Media width */
  width?: number | null
  /** Media height */
  height?: number | null
  /** Video codec */
  codec?: string | null
}
export type FileDesc = {
  /** Full url of the file */
  uri: string | null
  /** File id */
  fileId: TypeId
  /** File extension */
  extension: FileExtension
  /** true if this file is original, false if it is a derivative */
  original: boolean
  /** File metadata. Contains optional values for width, height etc. */
  meta: Meta1
  /** File size in bytes */
  size: number
  createdAt: Instant
  updatedAt: Instant
}
export type UploadsTags = 'Post' | 'Avatar' | 'Header' | 'Album'
export type Upload = {
  /** Internal id */
  uploadId: TypeId
  /** User id this upload belongs to */
  userId: TypeId
  /** Type of the upload */
  uploadType: UploadType
  /** Meta data */
  meta: Meta
  /** Size in bytes of all the files in this upload */
  size: number
  /** List of all the files this upload has */
  files: FileDesc[]
  /** List of tags attached to upload */
  tags: UploadsTags[]
  /** Upload processing error */
  error?: string | null
  /** true if cached */
  cached?: boolean
  /** true if this upload is remote */
  remote?: boolean
  createdAt: Instant
  updatedAt: Instant
}
export type UserState = 'REGULAR' | 'SENSITIVE' | 'LIMITED' | 'SUSPENDED'
export type Emoji = {
  shortcode: string
  category?: string | null
  url: string
  staticUrl: string
  visibleInPicker?: boolean
}
export type UserFull = {
  /** internal id of this user */
  id: TypeId
  /** unique user name */
  username: string
  /** user's domain, remote only, empty for local */
  domain: string
  /** full name of the user */
  displayName: string
  /** User's summary or bio */
  summary: string
  /** URI of this user */
  uri: string
  /** User stats */
  stats: UserStats
  /** User fields */
  fields: {
    [key: string]: string
  }
  /** User's avatar */
  avatar?: Upload | null
  /** User's header */
  header?: Upload | null
  /** Note for this user */
  note?: string | null
  createdAt: Instant
  /** User's moderation state */
  state: UserState
  emojis?: Emoji[] | null
}
export type NonEmptyListUserFull = UserFull[]
export type Token = {
  /** Persistent Token */
  sessionKey: string
}
export type AccountWithUserAndToken = {
  account: Account
  users: NonEmptyListUserFull
  token: Token
}
export type ApiError = {
  /** Error code */
  errorCode: string
  /** Error message */
  message: string
  /** Link to documentation */
  docUrl?: string
}
export type AuthRequest = {
  /** Email or username */
  login: string
  /** Account's password */
  password: string
}
export type EmailChangeConfirm = {
  newEmail: string
}
export type PasswordUpdate = {
  newPassword: string
}
export type PwdRecoveryUpdate = {
  newPassword: string
}
export type PwdRecoveryRequest = {
  email: string
}
export type AccountWithUser = {
  account: Account
  users: NonEmptyListUserFull
  /** ID of currently selected user. If not provided, the first user is selected */
  selectedUserId?: TypeId | null
}
export type AccountCreate = {
  /** Username of the main user */
  username: string
  /** Display Name of the main user */
  displayName: string
  /** Accounts' email */
  email: string
  /** Accounts' password */
  password: string
  /** Optional summary of the user */
  summary: string
  /** Accounts' role */
  role: AccountRole
}
export type ApplicationDeveloper = {
  name: string
  website: string
}
export type Route = {
  path: string
  handler?: string | null
  default?: boolean | null
}
export type ApplicationVisibility = 'PUBLIC' | 'UNLISTED'
export type ApplicationType = 'REGULAR' | 'ERROR' | 'TOOLBAR'
export type Manifest = {
  name: string
  description: string
  version: string
  releasedOn: Instant
  developer: ApplicationDeveloper
  icons: string[]
  previews: string[]
  routes: Route[]
  image?: string | null
  command: string[]
  ports: number[]
  visibility: ApplicationVisibility
  type: ApplicationType
}
export type Application = {
  id: TypeId
  name: string
  manifest: Manifest
  enabled?: boolean
  updatedAt: Instant
}
export type ApplicationWithPin = {
  id: TypeId
  name: string
  manifest: Manifest
  enabled?: boolean
  updatedAt: Instant
  /** If this app is pinned then it is pin order, otherwise it is -1 */
  pinOrder?: number
}
export type ApplicationWithStats = {
  id: TypeId
  name: string
  manifest: Manifest
  enabled?: boolean
  updatedAt: Instant
  usersCount?: number
}
export type DataType =
  | 'ROOT'
  | 'USER'
  | 'USER_POSTS'
  | 'USER_RESUME'
  | 'USER_ADS'
  | 'GROUP'
  | 'GROUP_POSTS'
  | 'ORG'
  | 'ORG_POSTS'
  | 'ORG_JOBS'
export type FollowingStatus = 'PENDING' | 'ACCEPTED' | 'REJECTED'
export type Relationship = {
  following?: FollowingStatus | null
  followed?: FollowingStatus | null
}
export type User = {
  /** internal id of this user */
  id: TypeId
  /** unique user name */
  username: string
  /** user's domain, remote only, empty for local */
  domain: string
  /** full name of the user */
  displayName: string
  /** URI of this user */
  uri: string
  /** User's avatar */
  avatar?: Upload | null
  createdAt: Instant
  /** User's moderation state */
  state: UserState
  /** Relationship with this user */
  relationship?: Relationship | null
}
export type EpLocation = {
  /** The company's home city/town */
  city: string | null
  /** The company's state/province/region */
  stateProvince: string | null
  /** The company's country */
  country: string | null
  /** The company's time zone */
  timezone: string | null
  /** The company's postal code */
  postcode: string | null
}
export type EpData = {
  /** The name of the company/firm */
  name: string | null
  /** The industry */
  industry: string | null
  /** The number of employees */
  size: number | null
  /** The year of the company foundation */
  yearFounded?: number | null
  /** The company's website */
  website?: string | null
  /** The company's free text description */
  description?: string | null
  /** The list of company specialties */
  specialties?: string[] | null
  /** The company's location/address */
  location?: EpLocation | null
}
export type EmployerProfile = {
  /** internal Employer Profile id */
  profileId: TypeId
  /** Flag marking the 'current' profile */
  current?: boolean
  /** Employer Profile data */
  EPData: EpData
}
export type InstanceConfigurationPosts = {
  /** Maximum post size in characters */
  max_characters: number
  /** Maximum number of attachments in post */
  max_media_attachments: number
}
export type InstanceConfigurationAttachments = {
  /** Maximum image resolution allowed for upload */
  image_resolution_limit: number
  /** Maximum image size in bytes allowed for upload */
  image_size_limit: number
  /** Limit for width x height for uploaded images */
  image_matrix_limit: number
  /** List of supported image extensions */
  image_extensions: string[]
  /** Maximum video resolution allowed for upload */
  video_resolution_limit: number
  /** Maximum video size in bytes allowed for upload */
  video_size_limit: number
  /** Limit for width x height for uploaded videos */
  video_matrix_limit: number
  /** Maximum video duration allowed for upload */
  video_duration_limit: number
  /** List of supported video extensions */
  video_extensions: string[]
  /** Maximum audio size in bytes allowed for upload */
  audio_size_limit: number
  /** List of supported audio extensions */
  audio_extensions: string[]
  /** Maximum document size in bytes allowed for upload */
  doc_size_limit: number
  /** List of supported document extensions */
  doc_extensions: string[]
}
export type RegistrationMode = 'OPEN' | 'APPROVAL' | 'CLOSED'
export type InstanceRegistration = {
  /** Registration mode on this instance */
  mode: RegistrationMode
  /** Whether reason to join should be provided when signing up */
  ask_for_info: boolean
  /** Whether captha is shown to new users when signing up */
  captcha: boolean
  /** Message shown to guests when registration is closed */
  message: string
  /** Whether existing users can invite other users to join */
  invites: boolean
}
export type InstanceConfiguration = {
  /** Posts configuration */
  posts: InstanceConfigurationPosts
  /** Attachments configuration */
  media_attachments: InstanceConfigurationAttachments
  /** Registration configuration */
  registrations: InstanceRegistration
}
export type Rule = {
  /** The id of the rule */
  id: TypeId
  /** Rule text */
  text: string
  /** Rule hint */
  hint: string
  /** Ordering of the rule, lowest first */
  ordering: number
}
export type Instance = {
  /** URI of this instance */
  uri: string
  title: string
  short_description: string
  description: string
  email: string
  /** Version of the platform */
  version: string
  /** Build # of the platform */
  build: string
  /** Build timestamp */
  buildTimestamp: number
  urls: {
    [key: string]: string
  }
  stats: {
    [key: string]: number
  }
  thumbnail: string
  languages: string[]
  /** This instance configuration */
  configuration: InstanceConfiguration
  contact_account: {
    [key: string]: any
  }
  /** List of instance rules */
  rules: Rule[]
}
export type ApProtocol = 'TOOT' | 'FORUM'
export type JaAttachment = {
  /** The Attachment name */
  name: string
  /** The Attachment's  URL */
  url?: string | null
  /** The Attachment text */
  text?: string | null
}
export type JaData = {
  /** The list of the attachments */
  attachments?: JaAttachment[] | null
}
export type JobApplication = {
  /** Internal Job Application id */
  jobApplicationId: TypeId
  /** Internal Job Posting id */
  jobPostingId: TypeId
  /** Job Application data */
  jaData: JaData
}
export type Salary = {
  /** The lower boundary of the salary range */
  min: number | null
  /** The upper boundary of the salary range */
  max: number | null
  /** The currency in which the salary is paid */
  currency: string | null
  /** The pay type/period */
  payType: string | null
}
export type LocalDate = string
export type JpData = {
  /** The offered title */
  title: string | null
  /** The location of the job */
  location: string | null
  /** The employment type */
  employmentType: string | null
  /** The job description */
  jobDescription: string | null
  /** The list of responsibilities */
  responsibilities: string[] | null
  /** The list of required qualifications */
  qualificationsRequired?: string[] | null
  /** The list of required skills */
  skillsRequired?: string[] | null
  /** The list of required educations */
  educationRequired?: string[] | null
  /** The list of required experiences */
  experienceRequired?: string[] | null
  /** Monetary compensation offered */
  salary: Salary | null
  /** The list of benefits offered */
  benefits?: string[] | null
  /** The date of the posting */
  datePosted?: LocalDate | null
  /** The deadline for the application */
  applicationDeadline?: LocalDate | null
}
export type JobPosting = {
  /** internal Job Posting id */
  jobPostingId: TypeId
  /** Job Posting data */
  jpData: JpData
}
export type CpContact = {
  /** Candidate's email */
  email?: string | null
  /** Candidate's phone number */
  phone?: string | null
  /** Candidate's website */
  website?: string | null
  /** Candidate's linkedin */
  linkedin?: string | null
  /** Candidate's github */
  github?: string | null
  /** Candidate's bitbucket */
  bitbucket?: string | null
  /** Candidate's X */
  x?: string | null
}
export type CpLocation = {
  /** Candidate's city */
  city: string | null
  /** Candidate's state/province */
  stateProvince: string | null
  /** Candidate's country */
  country: string | null
  /** Candidate's postal code */
  postcode?: string | null
  /** Candidate's time zone */
  timezone?: string | null
}
export type CpSkill = {
  /** Skill name */
  name: string | null
  /** Mastery level */
  level: string | null
}
export type CpHistory = {
  /** Position held/Title */
  title: string | null
  /** Company name */
  company: string | null
  /** Company location/country/state/city */
  location: string | null
  /** Candidate's start date for the position */
  startDate: LocalDate | null
  /** Candidate's end date for the position */
  endDate?: LocalDate | null
  /** The position description, candidate's responsibilities, etc. */
  description?: string | null
}
export type CpEducation = {
  /** The name of the school */
  school: string | null
  /** The earned degree */
  degree: string | null
  /** The location of the school */
  location: string | null
  /** The start date */
  startDate: LocalDate | null
  /** The graduation/end date */
  endDate?: LocalDate | null
  /** The additional description */
  description?: string | null
}
export type CpCourse = {
  /** The name of the course */
  name: string | null
  /** The name of the institution/organization supplying the course */
  institution: string | null
  /** The start date */
  startDate: LocalDate | null
  /** The graduation/end date */
  endDate?: LocalDate | null
}
export type CpLanguage = {
  /** The official name of language */
  name: string | null
  /** The mastery level achieved */
  level: string | null
}
export type CpCertification = {
  /** The name of the certificate */
  name: string | null
  /** The name of the authority that granted the certificate */
  authority: string | null
  /** The date the certificate was obtained */
  dateObtained: LocalDate | null
}
export type CpProject = {
  /** The name of the project */
  name: string | null
  /** The description of the project */
  description?: string | null
  /** The URL/website of the project */
  url?: string | null
}
export type CpData = {
  /** The first name of the employee */
  firstName: string | null
  /** The last name of the employee */
  lastName: string | null
  /** The full name of the candidate */
  fullName?: string | null
  /** Contact information.  Phones, emails, websites, etc... */
  contact: CpContact | null
  /** Location/Address */
  location: CpLocation | null
  /** Current title/position/job */
  occupation: string | null
  /** Any relevant personal information */
  personal?: string | null
  /** Summary of candidate's offering and goals */
  summary?: string | null
  /** The list of candidate's skills */
  skills?: CpSkill[] | null
  /** The list of candidate's previous jobs */
  history?: CpHistory[] | null
  /** The list of candidate's education items */
  education?: CpEducation[] | null
  /** The list of candidate's completed courses */
  courses?: CpCourse[] | null
  /** The list of candidate's languages */
  languages?: CpLanguage[] | null
  /** The list of candidate's certification */
  certifications?: CpCertification[] | null
  /** The list of candidate's projects */
  projects?: CpProject[] | null
}
export type EmployeeProfile = {
  /** Internal Employer Profile id */
  profileId: TypeId
  /** Flag marking the 'current' profile */
  current?: boolean
  /** Employee Profile data */
  cpData: CpData
}
export type UsersList = {
  id: TypeId
  userId: TypeId
  name: string
  description?: string | null
  icon?: string | null
  private?: boolean
  pinned?: boolean
  createdAt: Instant
  updatedAt: Instant
  users: User[]
}
export type UserListCreate = {
  name: string
  description?: string | null
  icon?: string | null
  private?: boolean
  pinned?: boolean
}
export type UserNote = {
  ownerId: TypeId
  userId: TypeId
  text: string
}
export type NoteUpdate = {
  /** User id to set note for */
  userId: TypeId
  /** Note text */
  text: string
}
export type NotificationType =
  | 'FOLLOW'
  | 'FOLLOW_REQUEST'
  | 'LIKE'
  | 'COMMENT'
  | 'MENTION'
  | 'REPOST'
  | 'LIST_POST_CREATE'
export type NotificationStatus = 'UNREAD' | 'READ' | 'BLOCKED' | 'HIDDEN'
export type PostPrivacy = 'PUBLIC' | 'UNLISTED' | 'PRIVATE' | 'DIRECT'
export type PostStats = {
  likes?: number
  reposts?: number
  comments?: number
}
export type PostSettings = {
  commentsEnabled?: boolean
  sensitive?: boolean
}
export type Post = {
  /** internal post id */
  id: TypeId
  /** unique post uri */
  uri: string
  /** post text, raw or html depending on how it was requested */
  text: string
  /** replies path */
  path: TypeId[]
  /** post privacy level */
  privacy: PostPrivacy
  /** post protocol */
  protocol: ApProtocol
  /** id of a post this one was reposted from */
  repostOf?: TypeId | null
  /** Subject or summary line, below which post content is collapsed until expanded. */
  summary: string | null
  /** List of tags */
  tags: string[]
  /** List of mentions */
  mentions: string[]
  /** Stats */
  stats: PostStats
  /** Settings */
  settings: PostSettings
  createdAt: Instant
  updatedAt: Instant
  author: User
  attachments: Upload[]
  liked?: boolean | null
  reposted?: boolean | null
  deleted?: boolean | null
  emojis?: Emoji[] | null
  replies?: Post[] | null
  originalPost?: Post | null
  replyTo?: TypeId | null
  conversationId: TypeId
}
export type Notification = {
  id: TypeId
  userId: TypeId
  type: NotificationType
  status: NotificationStatus
  activityId?: TypeId | null
  fromUserId?: TypeId | null
  createdAt: Instant
  updatedAt?: Instant | null
  fromUser?: User | null
  post?: Post | null
}
export type CreatePost = {
  /** Post text */
  text: string
  /** Post privacy */
  privacy: PostPrivacy
  /** List with upload ids of attachments */
  attachments?: TypeId[] | null
  /** Enable/disable comments for the post */
  commentsEnabled?: boolean
  /** Mark status and attached media as sensitive. contentWarning should be provided when this flag is true. Defaults to false. */
  sensitive?: boolean
  /** Content warning. The post content and media will be hidden under the warning. */
  contentWarning?: string | null
  /** Protocol of the post */
  protocol: ApProtocol
}
export type PostEdit = {
  id: TypeId
  text: string
  summary?: string | null
  sensitive?: boolean | null
  attachments: Upload[]
  createdAt: Instant
  emojis?: Emoji[] | null
  protocol: ApProtocol
}
export type Like = {
  userId: TypeId
  postId: TypeId
  createdAt: Instant
}
export type ReportNote = {
  userId: TypeId
  note: string
  createdAt: Instant
}
export type Report = {
  id: TypeId
  uri: string
  comment: string
  fromUserId?: TypeId | null
  targetPostIds?: TypeId[] | null
  targetUserId?: TypeId | null
  createdAt: Instant
  updatedAt: Instant
  assignedUser?: TypeId | null
  actionTakenAt?: Instant | null
  actionTakenBy?: TypeId | null
  forwarded?: boolean
  notes: ReportNote[]
  fromUser?: User | null
  targetUser?: User | null
  posts?: Post[] | null
}
export type CreateReport = {
  comment: string
  targetUserId?: TypeId | null
  targetPostIds: TypeId[]
  forward?: boolean
}
export type SearchResult = {
  users: User[]
  posts: Post[]
  tags: string[]
}
export type JsonNodeType =
  | 'ARRAY'
  | 'BINARY'
  | 'BOOLEAN'
  | 'MISSING'
  | 'NULL'
  | 'NUMBER'
  | 'OBJECT'
  | 'POJO'
  | 'STRING'
export type JsonNode = {
  empty?: boolean
  valueNode?: boolean
  containerNode?: boolean
  missingNode?: boolean
  array?: boolean
  object?: boolean
  nodeType?: JsonNodeType
  pojo?: boolean
  number?: boolean
  integralNumber?: boolean
  floatingPointNumber?: boolean
  short?: boolean
  int?: boolean
  long?: boolean
  float?: boolean
  double?: boolean
  bigDecimal?: boolean
  bigInteger?: boolean
  textual?: boolean
  boolean?: boolean
  null?: boolean
  binary?: boolean
}
export type TagHistory = {
  uses?: number
  date: LocalDate
}
export type Tag = {
  id: TypeId
  name: string
  protocol: ApProtocol
  totalUses?: number | null
  history?: TagHistory[] | null
}
export type Result = {
  uri: string
  upload: Upload
}
export type Request1 = {
  extension: FileExtension
  /** User meta attached to upload. Can contain information like name etc. */
  meta?: {
    [key: string]: any
  } | null
  /** Tags attached to the upload */
  tags: UploadsTags[]
}
export type Request = {
  userId: TypeId
  tags: UploadsTags[]
  orderType: string
  page?: number
  limit?: number
}
export type PostAttachment = {
  postId: TypeId
  uploadId: TypeId
}
export type UploadUsages = {
  posts: PostAttachment[]
  avatars?: TypeId[] | null
  headers?: TypeId[] | null
}
export type Request2 = {
  time?: number
}
export type UserCreate = {
  /** Username */
  username: string
  /** Display Name */
  displayName: string
  /** User's bio */
  summary?: string | null
  /** Avatar upload id or null to remove */
  avatarId: TypeId | null
  /** Header upload id or null to remove */
  headerId: TypeId | null
  /** User's role */
  role: AccountRole
}
export type UsernameHistory = {
  username: string
  domain: string
  userId?: TypeId | null
  createdAt: Instant
}
export type UsernameHistories = {
  history: UsernameHistory[]
  maxRenames?: number
}
export type UserUpdate = {
  /** Display Name */
  displayName: string
  /** User's bio */
  summary: string
  /** Avatar upload id or null to remove */
  avatarId: TypeId | null
  /** Header upload id or null to remove */
  headerId: TypeId | null
  /** User fields */
  fields: {
    [key: string]: string
  }
  /** Username */
  username?: string | null
}
export type UserUpdateMedia = {
  /** Avatar upload id or null to remove */
  avatarId: TypeId | null
  /** Header upload id or null to remove */
  headerId: TypeId | null
}
export type FileStore = {
  readOnly?: boolean
  totalSpace?: number
  usableSpace?: number
  unallocatedSpace?: number
  blockSize?: number
}
export type UserPrincipalLookupService = object
export type FileSystem = {
  open?: boolean
  readOnly?: boolean
  separator?: string
  rootDirectories?: Path[]
  fileStores?: FileStore[]
  userPrincipalLookupService?: UserPrincipalLookupService
}
export type Path = {
  fileSystem?: FileSystem
  absolute?: boolean
  root?: Path
  fileName?: Path
  parent?: Path
  nameCount?: number
}
export type FileItem = {
  inMemory?: boolean
  file?: Path
  fileSize?: number
  inputStream?: Blob
}
export type MultivaluedMapStringString = {
  [key: string]: string[]
}
export type FormValue = {
  value?: string
  charset?: string
  fileItem?: FileItem
  fileName?: string
  headers?: MultivaluedMapStringString
}
export type MultipartFormDataInput = {
  values?: {
    [key: string]: FormValue[]
  }
}
export const {
  usePostApiV1AccountsAuthenticateMutation,
  usePutApiV1AccountsChangeNameMutation,
  useDeleteApiV1AccountsDeleteMutation,
  usePutApiV1AccountsEmailChangeByHashMutation,
  usePutApiV1AccountsEmailConfirmChangeByHashMutation,
  usePutApiV1AccountsEmailRequestChangeMutation,
  usePutApiV1AccountsPasswordConfirmChangeByHashMutation,
  usePostApiV1AccountsPasswordConfirmByHashMutation,
  usePutApiV1AccountsPasswordRequestChangeMutation,
  usePostApiV1AccountsPasswordResetMutation,
  useGetApiV1AccountsProfileQuery,
  usePostApiV1AccountsRegisterMutation,
  usePostApiV1AccountsReselectUserMutation,
  useGetApiV1AccountsByIdQuery,
  usePutApiV1AdminChangeRoleMutation,
  useDeleteApiV1AdminDeleteMutation,
  useGetApiV1AdminListQuery,
  useDeleteApiV1AdminPurgeMutation,
  useGetApiV1AdminShowQuery,
  useGetApiV1AdminShowUserQuery,
  usePostApiV1AppsMutation,
  useGetApiV1AppsEnabledQuery,
  useGetApiV1AppsFeaturedQuery,
  useGetApiV1AppsInstalledQuery,
  useGetApiV1AppsPinnedQuery,
  useGetApiV1AppsUserQuery,
  usePutApiV1AppsUserByAppIdMutation,
  useDeleteApiV1AppsUserByAppIdMutation,
  useDeleteApiV1AppsUserByAppIdPinMutation,
  usePutApiV1AppsUserByAppIdReorderMutation,
  useGetApiV1AppsByAppIdQuery,
  usePutApiV1AppsByAppIdMutation,
  useDeleteApiV1AppsByAppIdMutation,
  usePutApiV1AppsByAppIdDisableMutation,
  usePutApiV1AppsByAppIdEnableMutation,
  usePutApiV1AppsByAppIdFeaturedMutation,
  useDeleteApiV1AppsByAppIdFeaturedMutation,
  usePutApiV1AppsByAppIdFeaturedReorderMutation,
  useGetApiV1AppsByAppIdStatsQuery,
  usePutApiV1AppsByAppIdAndTypeDefaultMutation,
  usePutApiV1AppsByAppIdAndTypeUserDefaultMutation,
  useDeleteApiV1AppsByAppIdAndTypeUserDefaultMutation,
  useGetApiV1BlockQuery,
  usePostApiV1BlockByUserIdMutation,
  useDeleteApiV1BlockByUserIdMutation,
  useGetApiV1CustomEmojisQuery,
  usePostApiV1CustomEmojisMutation,
  useDeleteApiV1CustomEmojisMutation,
  useGetApiV1CustomEmojisExportQuery,
  usePostApiV1CustomEmojisImportMutation,
  useGetApiV1CustomEmojisSearchQuery,
  usePostApiV1CustomEmojisUploadMutation,
  useGetApiV1EmployerProfilesQuery,
  usePutApiV1EmployerProfilesMutation,
  usePostApiV1EmployerProfilesMutation,
  useGetApiV1EmployerProfilesUserByUserIdQuery,
  useGetApiV1EmployerProfilesByProfileIdQuery,
  useDeleteApiV1EmployerProfilesByProfileIdMutation,
  usePutApiV1EmployerProfilesByProfileIdCurrentMutation,
  useGetApiV1InstanceQuery,
  useGetApiV1InstanceDomainsQuery,
  useGetApiV1InstanceExtendedDescriptionQuery,
  useGetApiV1JobApplicationsQuery,
  usePutApiV1JobApplicationsMutation,
  usePostApiV1JobApplicationsMutation,
  useGetApiV1JobApplicationsByApplicationIdQuery,
  useDeleteApiV1JobApplicationsByApplicationIdMutation,
  useGetApiV1JobPostingsQuery,
  usePutApiV1JobPostingsMutation,
  usePostApiV1JobPostingsMutation,
  useGetApiV1JobPostingsByPostingIdQuery,
  useDeleteApiV1JobPostingsByPostingIdMutation,
  useGetApiV1JobProfilesQuery,
  usePutApiV1JobProfilesMutation,
  usePostApiV1JobProfilesMutation,
  useGetApiV1JobProfilesUserByUserIdQuery,
  useGetApiV1JobProfilesByProfileIdQuery,
  useDeleteApiV1JobProfilesByProfileIdMutation,
  usePutApiV1JobProfilesByProfileIdCurrentMutation,
  useGetApiV1JobsListStatsQuery,
  useGetApiV1JobsListStatsAllQuery,
  useGetApiV1JobsListsQuery,
  useGetApiV1JobsStatsQuery,
  useGetApiV1JobsStatsPerDayQuery,
  useGetApiV1ListsQuery,
  usePostApiV1ListsMutation,
  useGetApiV1ListsByListIdQuery,
  usePutApiV1ListsByListIdMutation,
  useDeleteApiV1ListsByListIdMutation,
  useGetApiV1ListsByListIdUsersQuery,
  usePostApiV1ListsByListIdUsersAndUserIdMutation,
  useDeleteApiV1ListsByListIdUsersAndUserIdMutation,
  useGetApiV1MuteQuery,
  usePostApiV1MuteByUserIdMutation,
  useDeleteApiV1MuteByUserIdMutation,
  useGetApiV1NotesQuery,
  usePutApiV1NotesMutation,
  useDeleteApiV1NotesByUserIdMutation,
  useGetApiV1NotificationsQuery,
  useGetApiV1NotificationsCountQuery,
  usePostApiV1NotificationsMarkAllMutation,
  useGetApiV1NotificationsByIdQuery,
  usePostApiV1NotificationsByIdMarkMutation,
  useGetApiV1PostsQuery,
  usePostApiV1PostsMutation,
  useGetApiV1PostsFollowingQuery,
  useGetApiV1PostsLikedQuery,
  useGetApiV1PostsMentionedQuery,
  useGetApiV1PostsMyQuery,
  useGetApiV1PostsRepliesQuery,
  useGetApiV1PostsByPostIdQuery,
  usePutApiV1PostsByPostIdMutation,
  useDeleteApiV1PostsByPostIdMutation,
  usePostApiV1PostsByPostIdBookmarkMutation,
  useDeleteApiV1PostsByPostIdBookmarkMutation,
  useGetApiV1PostsByPostIdCommentsQuery,
  usePostApiV1PostsByPostIdCommentsMutation,
  usePutApiV1PostsByPostIdCommentsAndCommentIdMutation,
  useDeleteApiV1PostsByPostIdCommentsAndCommentIdMutation,
  useGetApiV1PostsByPostIdEditsQuery,
  useGetApiV1PostsByPostIdLikesQuery,
  usePostApiV1PostsByPostIdLikesMutation,
  useDeleteApiV1PostsByPostIdLikesMutation,
  usePostApiV1PostsByPostIdRepostMutation,
  useGetApiV1ReportsQuery,
  usePostApiV1ReportsMutation,
  useGetApiV1ReportsByReportIdQuery,
  usePostApiV1ReportsByReportIdAddNoteMutation,
  usePostApiV1ReportsByReportIdAssignToSelfMutation,
  usePostApiV1ReportsByReportIdRemoveNoteMutation,
  usePostApiV1ReportsByReportIdReopenMutation,
  usePostApiV1ReportsByReportIdResolveMutation,
  usePostApiV1ReportsByReportIdUnassignMutation,
  useGetApiV1RulesQuery,
  usePutApiV1RulesMutation,
  usePostApiV1RulesMutation,
  useDeleteApiV1RulesByIdMutation,
  useGetApiV1SearchQuery,
  useGetApiV1SettingsByNamespaceQuery,
  usePutApiV1SettingsByNamespaceMutation,
  useDeleteApiV1SettingsByNamespaceMutation,
  useGetApiV1SettingsByNamespaceJsonQuery,
  useGetApiV1TagsFeaturedQuery,
  usePostApiV1TagsFeaturedByNameMutation,
  useDeleteApiV1TagsFeaturedByNameMutation,
  usePostApiV1TagsFollowByNameMutation,
  useGetApiV1TagsFollowedQuery,
  usePostApiV1TagsUnfollowByNameMutation,
  useGetApiV1TagsByNameQuery,
  usePostApiV1UploadsMutation,
  useDeleteApiV1UploadsBatchMutation,
  usePostApiV1UploadsListMutation,
  useGetApiV1UploadsUsagesQuery,
  useGetApiV1UploadsByIdQuery,
  useDeleteApiV1UploadsByIdMutation,
  usePutApiV1UploadsByIdMetaMutation,
  usePostApiV1UploadsByIdMetaMutation,
  usePutApiV1UploadsByIdTagsMutation,
  usePostApiV1UploadsByIdTagsMutation,
  usePostApiV1UploadsByIdThumbnailMutation,
  useGetApiV1UserDomainBlockQuery,
  usePostApiV1UserDomainBlockByDomainMutation,
  useDeleteApiV1UserDomainBlockByDomainMutation,
  useGetApiV1UsersQuery,
  usePostApiV1UsersMutation,
  useDeleteApiV1UsersMutation,
  useGetApiV1UsersConnectionsQuery,
  usePostApiV1UsersFollowByUsernameMutation,
  usePostApiV1UsersFollowByUsernameAcceptMutation,
  usePostApiV1UsersFollowByUsernameRejectMutation,
  usePostApiV1UsersFollowByUsernameUndoMutation,
  useGetApiV1UsersFolloweesQuery,
  useGetApiV1UsersFollowersQuery,
  useGetApiV1UsersRelationshipsQuery,
  useGetApiV1UsersRenameHistoryQuery,
  useGetApiV1UsersByUserIdQuery,
  usePutApiV1UsersByUserIdMutation,
  useDeleteApiV1UsersByUserIdDeleteMutation,
  useGetApiV1UsersByUserIdFollowersQuery,
  useGetApiV1UsersByUserIdFollowingQuery,
  usePutApiV1UsersByUserIdMediaMutation,
  usePutApiV1UsersByUserIdStateMutation,
  usePostFileUploadMutation,
} = injectedRtkApi
